export const RDS_DEFAULTS = {
    RDS_LANDER_BANNER_TITLE: () => `COMPLETE YOUR ENTRY TO`,
    RDS_LANDER_TIMER_MSG: () => `Hurry before it’s too late!`,
    RDS_LANDER_CTA: () => `Enter Now`,
    RDS_REG1_FORM_SUBTITLE: () =>
        `In order to claim your <span class="rds-theme-bold-text">$5,000</span> entry, please confirm your details`,
    RDS_REG1_CTA: () => `Continue`,
    RDS_DETAILS_FORM_CTA: () => `Submit Entry`,
    RDS_DETAILS_FORM_HEADER: firstName =>
        `${firstName}, confirm your details to complete your entry`,
    RDS_OFFERS_HEADER: firstName => `${firstName}, You've qualified for these offers`,
    RDS_SA_OFFER: () => `Click Here`,
    RDS_SA_OFFER_SMALL: () => `Click Here`,
    RDS_PG_PROGRESS_PT_1: () => 'Take Survey',
    RDS_PG_PROGRESS_PT_2_SHOW_ME: () => 'Bonus Offers',
    RDS_PG_PROGRESS_PT_2_OFFERS: () => 'View Offers',
    RDS_PG_PROGRESS_PT_3: () => 'Confirmation',
    RDS_SHOW_ME_HEADER: firstName =>
        `<span>Congratulations, ${firstName}!</span><p>You qualify for these special offers</p>`,
    RDS_SHOW_ME_HEADER_STANDALONE: firstName =>
        `<h3>Welcome Back, ${firstName}! </h3> <p> We've picked this special offer for you</p>`,
    RDS_ENTRY_HEADER: firstName => `${firstName}, Go Check Your Email To Confirm Your Entry.`,
    RDS_ENTRY_NOT_CONFIRMED: () => `Your entry is not confirmed`,
    RDS_ENTRY_EXTRA_OFFERS: () => `Here are some additional offers for you`,
    RDS_BACK_BTN_HEADER: () =>
        `<span>Congratulations!</span><p>You qualify for these special offers</p>`,
    RDS_GO_BACK_BTN: () => `Go Back to win $5000`,
    RDS_GO_BTN: () => `Register Again`,
    RDS_SURVEY_HEADER: () =>
        `Claim <span class="rds-theme-bold-text">$5000</span>  entry by answering few questions`,
    RDS_EVERYJOB_SUBTITLE: () => `This will help EveryJobForMe send you the best jobs`,
    RDS_EVERYJOB_TITLE: () => `Confirm Your Email`,
    RDS_FINAL_EXP_TITLE: () => `Have You Protected Your Family?`,
    RDS_FINAL_EXP_BODY: () =>
        `Help your family be prepared for the burden of final expenses. Select this offer for Easy, Affordable Coverage with No Medical Exam.`,
    RDS_FINAL_EXP_TCPA: () =>
        `By submitting this form, I agree that I am 18+ years of age and agree to this website's <a href='https://equoto.com/privacy-policy/' target='_blank' style='color: inherit;'>Privacy Policy</a> and <a href='https://equoto.com/terms-conditions/' target='_blank' style='color: inherit;'>Terms & Conditions</a> and I also hereby give my express written consent to receive marketing communications regarding a Final Expense quote via autodialed calls and/or SMS/MMS, and/or pre-recorded or artificial voice calls eQuoto and/or one or more of its marketing <a href='https://equoto.com/partners/' target='_blank' style='color: inherit;'>partners</a>, at the phone number and/or e-mail address provided to us, including wireless numbers, if applicable, even if I have previously registered the provided number on the Do Not Call Registry. Carrier SMS/MMS and data messaging rates may apply. This consent is not required as a condition to purchase services or products.`,
    EveryJobForMeSweepsTest: () => `Default`,
    RDS_SURVEY_LOADER_TITLE: () => 'Processing entry…',
    RDS_SURVEY_LOADER_STEPS: () =>
        'Verifying data,Submitting details,Loading 5k Prize Opportunity!',
    EquotoFinalExpenseTest: () => `Control`,
    RDS_CLERGY_TITLE: () =>
        `Have you or your loved one ever been sexually abused by a member of clergy?`,
    ClergyCreativeTest: () => `Control`,
    RDS_MULTIVALUE_QUESTION_SUB_TITLE: () => `Check all that apply & click DONE`,
    RDS_STAND_ALONE_EXTRA_OFFERS: () => `Here are some additional offers`,
};
