import { IdStore } from 'dataStore/index';
import Constants from 'common/constants';
import Utils from 'common/utils';

export const APP_SCOPES = {
    GLOBAL: 0,
    LANDING: 1,
    SURVEY: 2,
    OFFERS: 3,
    LISTINGS: 4,
};

export const APP_SCOPES_STR = {
    [APP_SCOPES.GLOBAL]: 'global',
    [APP_SCOPES.LANDING]: 'landing',
    [APP_SCOPES.SURVEY]: 'survey',
    [APP_SCOPES.OFFERS]: 'offers',
    [APP_SCOPES.LISTINGS]: 'listings',
};

export const getScopeStr = scopeInt => APP_SCOPES_STR[scopeInt];

export const getScope = scope => {
    if (Utils.isStringNullOrWhiteSpace(scope)) return APP_SCOPES.GLOBAL;
    const scopeKey = scope.toUpperCase().trim();

    return APP_SCOPES[scopeKey] || APP_SCOPES.GLOBAL;
};
export const getCurrentPageScope = () => {
    try {
        return APP_SCOPES[window.location.pathname.replace('/', '').toUpperCase()] || '';
    } catch (e) {
        console.error('Error getting page scope', e);
    }
};

export const getCurrentPageScopeStr = () => APP_SCOPES_STR[getCurrentPageScope()];

export const markScopeDataLoaded = scope => {
    const CS_ID = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.CS_ID);
    IdStore.storeIdForKey(`${CS_ID}_${Constants.ID_STORE_KEYS.CONFIG_SCOPES_LOADED}_${scope}`, '1');
    IdStore.storeIdForKey(
        `${CS_ID}_${
            Constants.ID_STORE_KEYS.CONFIG_SCOPES_LOADED
        }_${scope}_${window.location.hash.replace('#', '')}`,
        '1',
    );
};
export const isScopeDataLoaded = (scope = getCurrentPageScope()) => {
    const CS_ID = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.CS_ID);

    return !!IdStore.fetchIdForKey(
        `${CS_ID}_${Constants.ID_STORE_KEYS.CONFIG_SCOPES_LOADED}_${scope}`,
    );
};

export const isScopeDataLoadedWithHash = (scope = getCurrentPageScope()) => {
    const CS_ID = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.CS_ID);

    return !!IdStore.fetchIdForKey(
        `${CS_ID}_${
            Constants.ID_STORE_KEYS.CONFIG_SCOPES_LOADED
        }_${scope}_${window.location.hash.replace('#', '')}`,
    );
};
