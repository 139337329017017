import { StringHelper, AbTestHelper } from 'helpers';
import Constants from 'common/constants';
import IdStore from 'dataStore/idStore';
import EventManager from 'eventManager/eventManager';
import Utils from 'common/utils';

const parseValueToArray = value => {
    let arr = [];

    if (value && value.length) {
        arr = value.split(',');
    }

    return arr;
};

const checkValueInArray = (array, value) => {
    return array.includes(value);
};

const getCurrentRoute = () => {
    const pathname = window.location.pathname;

    return pathname.replace('/', '');
};

const isNull = value => {
    return typeof value === 'undefined' || value === null;
};

/**
 * Convert array to object with array values as object keys
 * @param {Array<String>} arr Array to converted into object
 * @param {any} [value] Value to be assigned to each key. Defaults to key
 * @returns {object} Returns generated object
 */
const arrayToObj = (arr, value) => {
    if (Array.isArray(arr) && arr.length) {
        const obj = arr.reduce((accumulator, currentValue) => {
            accumulator[currentValue] = value ? value : currentValue;

            return accumulator;
        }, {});

        return obj;
    }

    return {};
};

/**
 * Splits address into address object with line 1 and line 2 keys
 * @param { object } initial Address object containing line1 and line2 keys of address field
 * @returns { object } Returns generated address field on splitting
 */

const splitAddress = (address, keywords) => {
    let { line1, line2 } = address;

    if (line1.indexOf(' ') !== -1) {
        line1 = line1.toLowerCase().replace(/[^a-zA-Z\d ]/g, '');

        for (const kw of keywords.SPACED_ADDRESS_KEYWORD) {
            if (line1.includes(kw)) {
                const tmp = line1.split(kw);
                line2 = tmp[1] ? tmp[1].trim() : '';

                if (!line2) break;
                line1 = `${tmp[0]}${kw}`;
                address['line2'] = line2;
                address['line1'] = line1;
                break;
            }
        }

        if (StringHelper.isNull(line2)) {
            const words = line1.split(' ');

            for (const word of words) {
                if (word !== '' && keywords.ADDRESS_KEYWORD.indexOf(word) !== -1) {
                    const tmp = line1.toLowerCase().split(word);
                    line2 = tmp[1] ? tmp[1].trim() : '';

                    // eslint-disable-next-line max-depth
                    if (!line2) break;
                    line1 = `${tmp[0]}${word}`;
                    address['line2'] = line2;
                    address['line1'] = line1;
                    break;
                }
            }
        }
    }

    return address;
};

const convertRawPhoneNo = phone => {
    return phone.replace(/\D/g, '');
};

/**
 * Format Phone number
 * @param { string } raw phone number
 * @returns { string } Returns generated formate phone number
 */
const formatPhoneNumber = rawPhoneNumber => {
    if (!rawPhoneNumber) return '';

    const phoneNumber = convertRawPhoneNo(rawPhoneNumber);

    const partA = phoneNumber.substring(0, 3);
    const partB = phoneNumber.substring(3, 6);
    const partC = phoneNumber.substring(6, 10);

    if (partC) {
        return `(${partA}) ${partB}-${partC}`;
    }

    if (partB) {
        return `${partA}-${partB}`;
    }

    return phoneNumber;
};

const isValidPhone = phoneNo => {
    if (!phoneNo) return false;

    if (phoneNo.length !== 10) {
        return false;
    }

    return true;
};

const decodeEncodedURI = encodedValue => {
    if (encodedValue) {
        return decodeURIComponent(encodedValue);
    }

    return false;
};

const filteredCardsLessThanAmount = (cards, cardAmount) => {
    const filteredData = cards.filter(obj => {
        if (
            obj &&
            obj.rewardValue &&
            !isNaN(parseInt(obj.rewardValue.slice(1))) &&
            !isNaN(parseInt(cardAmount))
        ) {
            if (parseInt(obj.rewardValue.slice(1)) <= parseInt(cardAmount)) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    });

    return filteredData;
};

const isLander = () =>
    window.location.pathname === Constants.PAGE_ENDPOINTS.LANDING && window.location.hash === '#0';

const checkTfCurlAndLeadId = () => {
    const tfCertUrl =
        document.getElementById('xxTrustedFormToken_0') &&
        document.getElementById('xxTrustedFormToken_0').value;
    const leadId =
        document.getElementById('leadid_token') && document.getElementById('leadid_token').value;

    if (tfCertUrl && leadId) {
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.REG2_TRUSTED_FORM_CERT_URL, tfCertUrl);
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.REG2_LEADID, leadId);

        return true;
    }

    return false;
};

const triggerPreppingEvent = (eventType, dealId, data) => {
    const campaignDetailJson = AbTestHelper.getCampaignDetailMapping() || {};
    const campaignDetail =
        campaignDetailJson && campaignDetailJson.hasOwnProperty(dealId)
            ? campaignDetailJson[dealId]
            : {};

    const eventData = { ...data, ...campaignDetail };

    EventManager.sendEvent(
        eventType,
        {
            ...eventData,
        },
        Constants.EVENT_TYPE.ADS,
    );
};

const antiSpamPixel = cb => {
    if (IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.ASA_PIXEL)) {
        return;
    }
    // eslint-disable-next-line camelcase
    const cs_id = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.CS_ID);
    const source = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_SOURCE);
    const campaign = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_CAMPAIGN);
    const term = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_TERM);
    const medium = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_MEDIUM);
    const svid = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.SURVEY_ID);
    const uuid = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UUID);
    const sid = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.SESSION_ID);
    const ps = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.PUBLISHER_SUBID);
    const pss = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.PUBLISHER_SUB_SOURCE);
    const route = window.location.pathname;
    const hash = window.location.hash;
    let templateId;

    if (route === Constants.PAGE_ENDPOINTS.LANDING) {
        if (hash === '#0') {
            templateId = Constants.ANTI_SPAM_TEMPLATE_ID['/landing']['lander'];
        } else {
            templateId = Constants.ANTI_SPAM_TEMPLATE_ID['/landing']['registration'];
        }
    } else {
        templateId = Constants.ANTI_SPAM_TEMPLATE_ID[route];
    }

    Utils.addScript(
        {
            type: 'text/javascript',
            id: 'antisp',
        },
        // eslint-disable-next-line camelcase
        `try{var saScript=document.createElement("script");saScript.src="https://pxlrtpge-a.akamaihd.net/javascripts/browserfp.min.js?templateId=${templateId}",saScript.async="async",saScript.onload=function(){try{var t=new browserfp,a={template_id:${templateId},sid:"${sid}",uuid:"${uuid}",ps:"${ps}",pss:"${pss}",svid:"${svid}",utm_campaign:"${campaign}",utm_medium:"${medium}",utm_term:"${term}",utm_source:"${source}",cs_id:"${cs_id}",test:${
            Utils.isTestEnv() ? 1 : 0
        }};t.setPageAttr(a),t.sendViewData()}catch(t){}},document.body.appendChild(saScript)}catch(t){}
        `,
        (() => {
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.ASA_PIXEL, 'success');
            cb();
        })(),
    );
};

export {
    parseValueToArray,
    checkValueInArray,
    getCurrentRoute,
    isNull,
    arrayToObj,
    splitAddress,
    formatPhoneNumber,
    isValidPhone,
    convertRawPhoneNo,
    decodeEncodedURI,
    filteredCardsLessThanAmount,
    isLander,
    checkTfCurlAndLeadId,
    triggerPreppingEvent,
    antiSpamPixel,
};
