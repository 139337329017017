import * as moment from 'moment';
import UrlManager from 'common/urlManager';
import Constants from 'common/constants';
import Utils from 'common/utils';
import { IdStore } from 'dataStore/index';
import { SessionHelper, StringHelper } from 'helpers';
import Features from './features';
import Lander from './lander';
import ABTests from './abTests';
import FeatureSets from './featureSets';
import GiftCards from './giftCards';
import { KeyMap as FeatureMap } from './constants/features';
import { KeyMap as LanderMap } from './constants/landers';
import { GenderMap } from './constants/common';
import { fetchRetry, addValueToObject } from './utils';
import UserDataValidator from 'common/userDataValidator';
import flavors, { getFlavor } from 'flavors.macro';
import DateUtils from 'common/DateUtils';
import {
    APP_SCOPES,
    getCurrentPageScope,
    getScopeStr,
    markScopeDataLoaded,
} from 'common/scopeUtils';

flavors();
const flavor = getFlavor('layout-theme');

class RootProvider {
    constructor() {
        this.data = null;
        this.Features = null;
        this.Lander = null;
        this.ABTests = null;
        this.GiftCards = null;
    }

    // eslint-disable-next-line max-lines-per-function
    async fetchConfig(aScopes) {
        // get data from API
        const userDetails =
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_DETAILS) &&
            JSON.parse(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_DETAILS));
        const oldUser = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_ID);
        const surveyId = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.SURVEY_ID);
        const keyword = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_TERM);
        const jobType = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL);
        const landingKw = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.LANDING_KEYWORD);
        const firstName = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.FNAME);
        const lastName = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.LNAME);
        const email = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.EMAIL);
        const addr = Utils.filterInvalidUrlInput(
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.ADDRESS),
        );
        const phone = Utils.filterInvalidUrlInput(
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.PHONE),
        );
        const uDob = Utils.filterInvalidUrlInput(
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.DOB),
        );
        const dobMoment = Utils.parseDateFromUrl();
        const isPiiPresent =
            !StringHelper.isNull(firstName) &&
            !StringHelper.isNull(lastName) &&
            !StringHelper.isNull(email) &&
            !UserDataValidator.isValidEmail(email);
        const isPiiReg2Present =
            !StringHelper.isNull(addr) &&
            !StringHelper.isNull(uDob) &&
            dobMoment &&
            dobMoment.isValid &&
            dobMoment.isValid() &&
            !DateUtils.isAboveTeenage(dobMoment) &&
            !StringHelper.isNull(phone);
        const urlJobType = Utils.getValForKeyFromCurrentUrl('jt');
        const isSub = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_STATUS_IS_SUBSCRIBED);
        const isReg = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_STATUS_IS_REGISTERED);
        const userStatus = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_STATUS);
        const userStatusCurrent = IdStore.fetchIdForKey(
            Constants.ID_STORE_KEYS.USER_STATUS_CURRENT,
        );
        const utmSource = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_SOURCE);

        const params = {};

        addValueToObject(params, 'scope', aScopes.map(getScopeStr).join(','));
        addValueToObject(
            params,
            'utm_source',
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_SOURCE),
        );
        addValueToObject(
            params,
            'utm_campaign',
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_CAMPAIGN),
        );
        addValueToObject(
            params,
            'utm_medium',
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_MEDIUM),
        );
        addValueToObject(params, 'utm_term', keyword);
        addValueToObject(params, 'job_type', jobType);
        addValueToObject(params, 'duplicate_user', SessionHelper.timeSinceFirstVisit());
        const giftCardId =
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.GIFTCARD_ID) ||
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GIFTCARD_ID);
        addValueToObject(params, 'key_vals', [
            `surveyId:${encodeURIComponent(Utils.isNull(surveyId) ? '' : surveyId)}`,
            `isRegistered:${
                IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GTM_IS_REGISTERED) || '0'
            }`,
            `isRegistered:${Utils.isEmptyStr(oldUser) ? '0' : '1'}`,
            `isKeywordEqualsJobType:${jobType === keyword ? '1' : '0'}`,
            `isKeywordPresent:${Utils.isEmptyStr(keyword) ? '0' : '1'}`,
            `isLandingJTPresent:${Utils.isEmptyStr(Utils.getJobType().jobType) ? '0' : '1'}`,
            `isUrlJtPresent:${StringHelper.isNull(urlJobType) ? '0' : '1'}`,
            `isUtmSourcePresent:${Utils.isNull(utmSource) ? '0' : '1'}`,
            `isKeywordEqualsBrand:${
                !StringHelper.isNull(landingKw) && landingKw.toLowerCase() === jobType.toLowerCase()
                    ? '1'
                    : '0'
            }`,
            `isJobTypeEqualsBrand:${
                Utils.isJobTypeAllowed(jobType && jobType.toLowerCase()) ? '1' : 0
            }`,
            `isNonDefaultJobTypePresent:${
                Utils.isEmptyStr(jobType) || jobType === Constants.DEFAULT_KW ? '0' : '1'
            }`,
            `isPIIFound:${isPiiPresent ? '1' : '0'}`,
            `isPIIReg2Found:${isPiiReg2Present ? '1' : '0'}`,
            `giftCardId:${encodeURIComponent(Utils.isNull(giftCardId) ? '' : giftCardId)}`,
            `is_sub:${isSub}`,
            `is_reg:${isReg}`,
            `userStatus:${userStatus}`,
            `userStatusCurrent:${userStatusCurrent}`,
            `${Constants.ID_STORE_KEYS.EXT1}:${IdStore.fetchIdForKey(
                Constants.ID_STORE_KEYS.EXT1,
            )}`,
            `${Constants.ID_STORE_KEYS.EXT2}:${IdStore.fetchIdForKey(
                Constants.ID_STORE_KEYS.EXT2,
            )}`,
            `${Constants.ID_STORE_KEYS.EXT3}:${IdStore.fetchIdForKey(
                Constants.ID_STORE_KEYS.EXT3,
            )}`,
            `${Constants.ID_STORE_KEYS.EXT4}:${IdStore.fetchIdForKey(
                Constants.ID_STORE_KEYS.EXT4,
            )}`,
            `${Constants.ID_STORE_KEYS.EXT5}:${IdStore.fetchIdForKey(
                Constants.ID_STORE_KEYS.EXT5,
            )}`,
        ]);

        if (userDetails && !Utils.isEmptyObj(userDetails)) {
            const { dob, gender } = userDetails;

            if (!Utils.isEmptyStr(dob)) {
                params.age = moment().diff(dob, 'years');
            }

            if (!Utils.isEmptyStr(gender)) {
                params.gender = GenderMap[gender];
            }
        }

        addValueToObject(params, 'domain', flavor);

        const data = await fetchRetry(
            `${UrlManager.getConfigurationProviderUrl()}?${Utils.encodeObjectToUrlArgs(params)}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            },
            2,
        );

        if (!Utils.isNull(data)) {
            aScopes.forEach(markScopeDataLoaded);
            // IdStore.sync();
        }

        IdStore.storeIdForKey(
            Constants.ID_STORE_KEYS.ROASCONFIG_CAMPAIGN,
            data.roasConfig.campaign,
        );
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.ROASCONFIG_EXISTS, data.roasConfig.exists);
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.ROASCONFIG_PUBCODE, data.roasConfig.pubCode);

        return data;
    }

    mergeConfigData(data, prevData, key) {
        const currDataVal = data[key] || [];
        const prevDataVal = prevData[key] || [];

        return [...prevDataVal, ...currDataVal];
    }

    async fetchScopeConfig(aScopes) {
        const data = await this.fetchConfig(aScopes);
        const prevData = this.data || {};

        const currAbtMap = data.abtMap || [];
        const currFeatureMap = data.featureMap || [];
        const currFeatureSets = data.featureSets || [];
        const prevAbtMap = prevData.abtMap || [];
        const prevFeatureMap = prevData.featureMap || [];
        const prevFeatureSets = prevData.featureSets || [];

        const filteredAbtMap = currAbtMap.filter(
            abtMap => !prevAbtMap.find(i => i.channel === abtMap.channel),
        );
        const filteredFeatureSets = Array.from(new Set([...prevFeatureSets, ...currFeatureSets]));

        const mergedData = {
            ...prevData,
            abtMap: [...prevAbtMap, ...filteredAbtMap],
            featureMap: [...prevFeatureMap, ...currFeatureMap],
            featureSets: filteredFeatureSets,
        };

        this.data = mergedData;
        this.Features && this.Features.setFeatures(currFeatureMap);
        this.ABTests && this.ABTests.setABTests(filteredAbtMap);
        this.FeatureSets && this.FeatureSets.set(filteredFeatureSets);
    }

    /**
     * Initialize the application, leverage on caching
     */
    async initialize() {
        const CS_ID = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.CS_ID);

        try {
            if (!Utils.isEmptyStr(CS_ID)) {
                const dataCache = IdStore.fetchIdForKey(
                    `${CS_ID}_${Constants.ID_STORE_KEYS.PROVIDER_DATA}`,
                );

                if (!Utils.isEmptyStr(dataCache)) {
                    try {
                        this.data = JSON.parse(dataCache);
                    } catch (exception) {
                        console.error('FAILED to parse cached provider data - ', exception);
                    }
                }
            }

            if (Utils.isNull(this.data)) {
                // get data from API
                this.data = await this.fetchConfig([APP_SCOPES.GLOBAL, getCurrentPageScope()]);

                if (Utils.isNull(this.data)) {
                    this.Features = new Features({});
                    this.Lander = new Lander({});
                    this.ABTests = new ABTests({});
                    this.GiftCards = new GiftCards({});
                    this.FeatureSets = new FeatureSets({});

                    return false;
                }

                // Set sub providers
                this.Features = new Features(this.data);
                this.Lander = new Lander(this.data);
                this.ABTests = new ABTests(this.data);
                this.GiftCards = new GiftCards(this.data);
                this.FeatureSets = new FeatureSets(this.data);

                // set config data in IdStore
                IdStore.storeIdForKey(
                    `${CS_ID}_${Constants.ID_STORE_KEYS.PROVIDER_DATA}`,
                    JSON.stringify(this.data),
                );
                this.data.pubSourceTypeConfig &&
                    IdStore.storeIdForKey(
                        Constants.ID_STORE_KEYS.PUB_SOURCE_TYPE_CONFIG,
                        JSON.stringify(this.data.pubSourceTypeConfig || ''),
                    );

                return true;
            }
        } catch (exception) {
            console.log('FAILED to load provider data - ', exception);
        }
        this.Features = new Features({});
        this.Lander = new Lander({});
        this.ABTests = new ABTests({});
        this.GiftCards = new GiftCards({});

        return false;
    }

    /**
     * Force refresh of config data
     */
    async reset() {
        const CS_ID = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.CS_ID);

        if (!Utils.isEmptyStr(CS_ID)) {
            IdStore.removeIdForKey(`${CS_ID}_${Constants.ID_STORE_KEYS.PROVIDER_DATA}`);
        }
        await this.initialize();
    }
}

// export providers
export const ConfigProvider = new RootProvider();
export { FeatureMap, LanderMap };
