import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    container: {
        padding: 20,
        textAlign: 'center',
        minHeight: 'calc(100vh - 60px)',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-start',
            marginTop: 30,
        },
    },
}));
