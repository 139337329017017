import { bugsnagClient } from './bugsnag';

class RemoteLogger {
    /**
     * This method initializes the client.
     * But currently it need not do anything since importing
     * the bugsnag-client is sufficient, as long as the code-path
     * arrives here. It's rather icky to have bugsnag directly
     * imported in the index.js of the main-app. Keeping it separated
     * this way. Thanks for attending my talk :p
     */
    initialise() {
        // pass
    }

    log(errorObj) {
        console.log('Sending error to bugsnag - ', errorObj);
        console.log(errorObj);
        bugsnagClient.notify(errorObj);
    }

    logStr(errStr) {
        console.log(errStr);
        this.log(new Error(errStr));
    }
}

const loggerObj = new RemoteLogger();
export default loggerObj;
