import Constants from 'common/constants';

export const Links = {
    CONTACT_US: {
        link: Constants.PAGE_ENDPOINTS.CONTACTUS,
        text: 'Support',
    },
    PRIVACY_POLICY: {
        link: Constants.PAGE_ENDPOINTS.PRIVACY_POLICY,
        text: 'Privacy Policy',
    },
    CALIFORNIA_PRIVACY: {
        link: Constants.PAGE_ENDPOINTS.CCPA,
        text: 'CA Consumers: Do Not Sell My Info',
    },
    DO_NOT_SELL_INFO: {
        link: 'https://rewardsurveyusa.com/california-privacy-notice#your-rights-and-choices',
        text: 'Do Not Sell My Info',
    },
    TERMS_OF_USE: {
        link: Constants.PAGE_ENDPOINTS.TERMSANDCONDITIONS,
        text: 'Terms & Conditions',
    },
    UNSUBSCRIBE: {
        link: '/unsubscribe',
        text: 'Unsubscribe',
    },
    OFFICIAL_RULES: {
        link: Constants.PAGE_ENDPOINTS.OFFICIAL_RULES,
        text: 'Official Rules',
    },
};

export const ExternalLinks = [];
