import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    container: {
        height: '60px',
    },
    bgWhite: {
        backgroundColor: theme.palette.common.white,
    },
    bgBlack: {
        backgroundColor: '#11230C',
    },
    picture: {
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& img': {
            maxHeight: 42,
            width: 'auto',
            [theme.breakpoints.down('sm')]: {
                width: 132,
                maxHeight: 32,
            },
        },
    },
}));
