import Utils from 'common/utils';
import AdsDataSource from 'datasource/adsDataSource';
import TargetingManager from 'targetingManager';
import IdStore from 'dataStore/idStore';

/**
 *
 * @param {string} auId ad unit id
 * @param {Object} targetingParams custom targeting parameters to be passed in the call
 * @param {Object} targetingParams.extraParams
 * @param {Object} targetingParams.userParams
 * @param {Object} targetingParams.geoParams
 * @param {string} cmpName campaign name to match
 * @param {Object} storeKeyVal key value pair to be stored in session on success
 * @param {string} storeKeyVal.key
 * @param {string} storeKeyVal.value
 * @param {function} cb callback function to be executed on success
 * @return {undefined}
 */
const getAdUnit = async (auId, targetingParams, cmpName, storeKeyVal, cb) => {
    if (!auId) {
        console.error('Ad unit id not found.');

        return;
    }

    let extraParams = {};
    let userParams = {};
    let geoParams = {};

    if (!Utils.isEmptyObj(targetingParams)) {
        extraParams = targetingParams.extraParams;
        userParams = targetingParams.userParams;
        geoParams = targetingParams.geoParams;
    }

    try {
        const targetingParams = TargetingManager.fetchTargetingParams({
            extraParams,
            userParams,
            geoParams,
        });
        const adsUnitData = await AdsDataSource.fetchAdsForAdUnit(targetingParams, auId);

        if (!Utils.isEmptyObj(storeKeyVal)) {
            IdStore.storeIdForKey(storeKeyVal.key, storeKeyVal.value);
        }

        if (adsUnitData && adsUnitData.ad && !Utils.isEmptyObj(adsUnitData.ad)) {
            const { campaignName, campaignId, advertiserName, advId } = adsUnitData.ad;

            if (campaignName && cmpName) {
                if (campaignName.toLowerCase().includes(cmpName.toLowerCase())) {
                    const eventData = {
                        advid: advId,
                        advnm: advertiserName,
                        cmpid: campaignId,
                        cmpnm: campaignName,
                    };
                    await cb(eventData);
                }
            }
        }
    } catch (err) {
        console.error(`Error while fetching AdUnit for ${cmpName} - `, err);
    }
};

export const formatAdUnit = (adUnit = {}) => {
    const data = JSON.parse(JSON.stringify(adUnit));
    const ad = data.ad || {};

    if (ad.revType && ad.revType.toLowerCase() === 'cpa') {
        // eslint-disable-next-line max-depth
        if (ad.customAdInfo && ad.customAdInfo.cpa) {
            ad.cpa = ad.customAdInfo.cpa;
        } else if (ad.bid) {
            ad.cpa = ad.bid;
        }
    }

    if (ad.revType && ad.revType.toLowerCase() === 'cpc') {
        // eslint-disable-next-line max-depth
        if (ad.customAdInfo && ad.customAdInfo.cpc) {
            ad.cpc = ad.customAdInfo.cpc;
        } else if (ad.bid) {
            ad.cpc = ad.bid;
        }
    }

    ad.ervn = ad.cpa || ad.bid;

    return data;
};

export { getAdUnit };
