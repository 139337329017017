import Utils from '../../common/utils';
import { IdStore } from 'dataStore';
import Constants from 'common/constants';
import { CommonHelper } from 'helpers';

// eslint-disable-next-line max-lines-per-function
const inboundPreping = async (prepingData, storeKeyVal) => {
    if (Utils.isEmptyObj(prepingData)) {
        console.error('Preping data not found');

        return;
    }

    if (!Utils.isEmptyObj(storeKeyVal)) {
        storeKeyVal.keys.forEach(key => {
            const oldPrepingExists = IdStore.fetchIdForKey(key);

            if (!Utils.isNull(oldPrepingExists)) {
                IdStore.removeIdForKey(key);
            }
        });
    }

    const { email } = prepingData;

    try {
        CommonHelper.triggerPreppingEvent(Constants.EVENT.PREPING_SEND, 'INB_MVA_sweeps');
        CommonHelper.triggerPreppingEvent(Constants.EVENT.PREPING_SEND, 'INB_WC_sweeps');
        CommonHelper.triggerPreppingEvent(Constants.EVENT.PREPING_SEND, 'INB_Debt_sweeps');

        await fetch(Constants.API_PROXY_URL, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify({
                url: `${Constants.PREPING_URLS.INBOUND}${email}${Constants.INBOUND_2}`,
            }),
        })
            .then(str => str.text())
            .then(data => {
                const res = JSON.parse(data);

                if (res && res.status === 'EMAIL_NOT_FOUND_IN_DB') {
                    CommonHelper.triggerPreppingEvent(
                        Constants.EVENT.PREPING_SUCCESS,
                        'INB_MVA_sweeps',
                    );
                    CommonHelper.triggerPreppingEvent(
                        Constants.EVENT.PREPING_SUCCESS,
                        'INB_WC_sweeps',
                    );
                    CommonHelper.triggerPreppingEvent(
                        Constants.EVENT.PREPING_SUCCESS,
                        'INB_Debt_sweeps',
                    );

                    if (!Utils.isEmptyObj(storeKeyVal)) {
                        storeKeyVal.keys.forEach(key => {
                            IdStore.storeIdForKey(key, storeKeyVal.value);
                        });
                    }
                } else {
                    CommonHelper.triggerPreppingEvent(
                        Constants.EVENT.PREPING_FAILURE,
                        'INB_MVA_sweeps',
                    );
                    CommonHelper.triggerPreppingEvent(
                        Constants.EVENT.PREPING_FAILURE,
                        'INB_WC_sweeps',
                    );
                    CommonHelper.triggerPreppingEvent(
                        Constants.EVENT.PREPING_FAILURE,
                        'INB_Debt_sweeps',
                    );
                }
            });
    } catch (e) {
        CommonHelper.triggerPreppingEvent(Constants.EVENT.PREPING_FAILURE, 'INB_MVA_sweeps');
        CommonHelper.triggerPreppingEvent(Constants.EVENT.PREPING_FAILURE, 'INB_WC_sweeps');
        CommonHelper.triggerPreppingEvent(Constants.EVENT.PREPING_FAILURE, 'INB_Debt_sweeps');
        console.log(e);
    }
};

export { inboundPreping };
