import React from 'react';
import RouterPrefixHandler from 'common/routePrefixHandler';
import flavors, { getFlavor } from 'flavors.macro';
import { useStyles } from './header.styles';
import { CommonHelper, AbTestHelper } from 'helpers';
import Utils from 'common/utils';
import Constants from 'common/constants';

flavors();

/**
 * App header
 * @param {*} props
 * @returns Header
 */
export const Header = props => {
    const classes = useStyles(props);
    const flavor = getFlavor('layout-theme');

    return (
        <header
            className={`${classes.container} ${
                !Utils.checkMobileDevice() && !CommonHelper.isLander() && classes.bgWhite
            } ${
                window.location.pathname === Constants.PAGE_ENDPOINTS.ADS &&
                AbTestHelper.midPathOffersBackgroundTest() &&
                classes.bgWhite
            } ${
                window.location.pathname === Constants.PAGE_ENDPOINTS.ADS &&
                AbTestHelper.midPathOffersBackgroundTestVariant() &&
                classes.bgBlack
            }`}>
            <picture className={classes.picture}>
                <img
                    alt="Logo"
                    height="42"
                    src={RouterPrefixHandler.getImagePath(`images/${flavor}/logos/site.png`)}
                    width="150"
                />
            </picture>
        </header>
    );
};
