import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import { useStyles } from './page.styles';
import LogoLoader from '../logo';
import CommonWrapper from '../../wrapper/commonWrapper';
import Header from 'flavors/dailySweeps/components/topBar';
import Footer from 'flavors/dailySweeps/components/footer';

/**
 * PageLoader
 * @param {*} props
 * @returns PageLoader
 */
export const PageLoader = props => {
    const classes = useStyles(props);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <Header />
            <section>
                <Grow in={true}>
                    <Grid
                        alignItems="center"
                        className={classes.container}
                        container
                        direction="column"
                        spacing={2}>
                        <CommonWrapper>
                            <Grid item>
                                <LogoLoader />
                            </Grid>
                        </CommonWrapper>
                    </Grid>
                </Grow>
            </section>
            <Footer />
        </div>
    );
};
