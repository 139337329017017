export const Labels = {
    BIRTH_FIELDS_PLACEHOLDER: {
        DATE: 'Day',
        MONTH: 'Month',
        YEAR: 'Year',
    },
    LANDER_CTA: 'Enter Now',
    REG1_CTA: 'Continue',
    REG2_CTA: 'Continue',
    REG2_SUBMITTING: 'Submitting',
    REG1_FORM_PLACEHOLDER: {
        FIRST_NAME: 'First Name',
        LAST_NAME: 'Last Name',
        ZIP_CODE: 'Zip code',
    },
    REG2_FORM_PLACEHOLDER: {
        STREET_ADDRESS: 'Street Address',
        PHONE_NUMBER: 'Phone No.',
        DATE_OF_BIRTH: 'Date of Birth',
    },
    USER_DETAILS_TCPA: {
        PART1: `I agree to receive SMS text messages (messages and data rates may apply) from dailysweeps.co `,
        MARKETING_PARTNERS: `Marketing Partners`,
        PART2: `at the phone number provided (including my wireless), whether or not the number is the federal or state do not call registry. By checking this box and clicking the “Continue” button below, I represent that I am 18+ years of age and that I have read and agreed to the  <a href="/terms-and-conditions" target="_blank">Terms of Use</a>, <a href="/privacy-policy" target="_blank">Privacy Policy</a> and <a href="/california-privacy-notice" target="_blank">CA Privacy Notice</a>.  I understand my consent is not required as a condition of purchasing any goods or services. To receive this information without providing this consent,`,
        PART3: `For SMS message campaigns: Text STOP to stop and HELP for help. Msg & data rates may apply. Message frequency varies; max. 30/month.`,
        SKIP: `skip here`,
    },
    EMAIL_PLACEHOLDER: 'Email',
    DETAILS_CONFIRMATION_TCPA_LABEL: 'I Agree',
    MARKETING_PARTNERS: 'Marketing Partners',
    SKIP_HERE: 'skip here',
    SKIP: 'Skip',
    CLAIM_OFFER: 'Claim Offer',
    UNSUBSCRIBE: 'Unsubscribe',
    GO_TO_HOME: 'Go to Home',
    SUBMIT: 'Submit',
    SPIN_NOW: 'Spin Now',
    SLOT_BUTTON_WIN: 'Claim Now',
};
