const ANALYTICS_ID = 'J1SSLQVDTQ';

export default class Analytics {
    static initialise() {
        /*
         * ReactGA.initialize(
         *     ANALYTICS_ID,
         *     {
         *         gaOptions: {
         *             // TODO: Set this back to 1 after going live
         *             // https://stackoverflow.com/a/24813500/1518924
         *             siteSpeedSampleRate: 100
         *         }
         *     }
         * );
         * ReactGA.pageview(window.location.pathname + window.location.search);
         * ReactGA.plugin.require('ecommerce')
         */
        window.gtag('config', ANALYTICS_ID);
    }

    static getAnalyticsInstance() {
        return this;
    }
}
