import Utils from 'common/utils';
import LocalStoreManager from './localStoreManager';
import SessionStoreManager from './sessionStoreManager';
import Constants from 'common/constants';

class IdStore {
    constructor() {
        // The keys that can be stored in local-storage
        this.localStoreKeys = [
            Constants.ID_STORE_KEYS.DEVICE_ID,
            Constants.ID_STORE_KEYS.USER_ID,
            Constants.ID_STORE_KEYS.GEO_CITY,
            Constants.ID_STORE_KEYS.GEO_COUNTRY,
            Constants.ID_STORE_KEYS.GEO_REGION,
            Constants.ID_STORE_KEYS.GEO_ZIP_CODE,
            Constants.ID_STORE_KEYS.SUB_DATA_KEY,
            Constants.ID_STORE_KEYS.USER_DETAILS,
            Constants.ID_STORE_KEYS.FIRST_VISIT,
            Constants.ID_STORE_KEYS.LAST_VISIT,
            Constants.ID_STORE_KEYS.VISIT_COUNT,
            Constants.ID_STORE_KEYS.REGISTERED_VISIT_COUNT,
            Constants.ID_STORE_KEYS.USER_STATUS,
            Constants.ID_STORE_KEYS.USER_STATUS_CURRENT,
            Constants.ID_STORE_KEYS.USER_STATUS_IS_SUBSCRIBED,
            Constants.ID_STORE_KEYS.USER_STATUS_IS_REGISTERED,
            Constants.ID_STORE_KEYS.IS_EMAIL_BLACK_LISTED,
            Constants.ID_STORE_KEYS.BASE_API_URL,
            Constants.ID_STORE_KEYS.PUBLISHER_COST_BASE_URL,
            Constants.ID_STORE_KEYS.CONFIGURATION_BASE_URL,
            Constants.ID_STORE_KEYS.PROXY_PROVIDER_URL,
            Constants.ID_STORE_KEYS.GIFT_CARD_SERVICE_BASE_URL,
        ];
    }

    /**
     * Stores key and val into db.
     *
     * @param {string} key
     * @param {string} val
     * @returns {(null|Error)} null if stored successfully, else error
     * @static
     */
    storeIdForKey(key, val) {
        if (Utils.isEmptyStr(key) || Utils.isEmptyStr(val)) {
            return new Error('key and value have to be non-empty strings');
        }
        console.log(`STORE: Storing ${key} -> ${val}`);

        let store = SessionStoreManager;

        if (this.isLocalStoreKey(key)) {
            store = LocalStoreManager;
        }

        store.setItem(key, val);

        return null;
    }

    /**
     * @param {string} key
     * @returns {(null|string)}
     */
    fetchIdForKey(key) {
        if (Utils.isEmptyStr(key)) {
            console.log('ID_STORE: key cannot be empty!');

            return null;
        }

        let store = SessionStoreManager;

        if (this.isLocalStoreKey(key)) {
            store = LocalStoreManager;
        }

        return store.getItem(key);
    }

    /**
     * @returns {(null|object)} null if no data in store, else a map with all the values.
     */
    fetchAllIds() {
        let localVals = LocalStoreManager.getAll();

        if (Utils.isNull(localVals)) {
            localVals = {};
        }
        let sessionVals = SessionStoreManager.getAll();

        if (Utils.isNull(sessionVals)) {
            sessionVals = {};
        }
        const allValues = Object.assign(localVals, sessionVals);

        if (Utils.isEmptyObj(allValues)) {
            return null;
        }

        return allValues;
    }

    fetchAllSessionIds() {
        let sessionVals = SessionStoreManager.getAll();

        if (Utils.isNull(sessionVals)) {
            sessionVals = {};
        }

        return sessionVals;
    }

    /**
     * Determines if the key can be stored in local-storage
     * @param {string} key
     */
    isLocalStoreKey(key) {
        if (Utils.isEmptyStr(key)) {
            return false;
        }

        return this.localStoreKeys.includes(key);
    }

    /**
     * @param {*} key
     */
    removeIdForKey(key) {
        if (Utils.isEmptyStr(key)) {
            return;
        }

        if (this.isLocalStoreKey(key)) {
            LocalStoreManager.removeItem(key);
        } else {
            SessionStoreManager.removeItem(key);
        }
    }

    sync() {
        SessionStoreManager.sync();
        LocalStoreManager.sync();
    }

    setSync() {
        SessionStoreManager.setMode(Constants.WEBSTORE_CONSTANTS.SYNC);
        LocalStoreManager.setMode(Constants.WEBSTORE_CONSTANTS.SYNC);
    }
}

const idStore = new IdStore();
export default idStore;
