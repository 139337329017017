import 'react-app-polyfill/stable';
import flavors, { getFlavor } from 'flavors.macro';
import { ErrBoundaryComponent, ErrLogger } from 'errLogger';
import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes';
import { BrowserRouter } from 'react-router-dom';
import Analytics from './analytics';
import Utils from 'common/utils';
import { IdStore } from 'dataStore/index';
import Constants from 'common/constants';

import { Container } from 'typedi';
import Filter from 'bad-words';
import { ConfigProvider } from 'providers';
import { ThemeProvider } from '@material-ui/core/styles';
import { DefaultTheme } from 'flavors/realDealSweeps/themes';
import { OfferWallEntryValidatorHelper, SessionHelper, AbTestHelper } from 'helpers';
import { trackUserStatus } from 'helpers/session/sessionHelpers';
import { Processors } from 'helpers/index';

import 'flavors/realDealSweeps/styles/index.scss';

flavors();

const ReactPlaceholder = 'react-survey-app';

const flavor = getFlavor('layout-theme');
const ErrorPage = React.lazy(() => import(`flavors/realDealSweeps/components/pages/error`));

function generateCsId() {
    return `${Utils.generateUUID()}`.replace(/-/g, '');
}

const setThemeVariables = () => {
    const theme = DefaultTheme;

    if (theme.palette.primary.main) {
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.THEME_COLOR, theme.palette.primary.main);
        IdStore.storeIdForKey(
            Constants.ID_STORE_KEYS.THEME_PRIMARY_COLOR,
            theme.palette.primary.main,
        );
    }

    if (theme.palette.primary.contrastText) {
        IdStore.storeIdForKey(
            Constants.ID_STORE_KEYS.THEME_PRIMARY_CONTRAST_TEXT_COLOR,
            theme.palette.primary.contrastText,
        );
    }

    if (theme.palette.secondary.main) {
        IdStore.storeIdForKey(
            Constants.ID_STORE_KEYS.THEME_SECONDARY_COLOR,
            theme.palette.secondary.main,
        );
    }

    if (theme.palette.secondary.contrastText) {
        IdStore.storeIdForKey(
            Constants.ID_STORE_KEYS.THEME_SECONDARY_CONTRAST_TEXT_COLOR,
            theme.palette.secondary.contrastText,
        );
    }

    if (theme.palette.background.default) {
        IdStore.storeIdForKey(
            Constants.ID_STORE_KEYS.THEME_DEFAULT_BACKGROUND_COLOR,
            theme.palette.background.default,
        );
    }
};

// eslint-disable-next-line max-lines-per-function
async function initialiseApp() {
    setThemeVariables();
    // Initializing logger
    ErrLogger.initialise();
    Analytics.initialise();
    Processors.preAppInitProcessor();

    // Disabling logs throughout the app if not in development
    if (false === Utils.isTestEnv()) {
        console.log = function () {};
        console.debug = function () {};
    }
    IdStore.removeIdForKey(Constants.ID_STORE_KEYS.ASA_PIXEL);

    const surveyId = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.SURVEY_ID);

    if (surveyId) {
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.SURVEY_ID, surveyId);
    } else {
        const defaultSid = flavor !== 'realDealSweeps' ? '47' : '60';
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.SURVEY_ID, defaultSid);
    }

    // Store the UTM entries if available
    const getNonEmptyVal = (val, defaultVal) => {
        if (Utils.isEmptyStr(val)) {
            return defaultVal;
        }

        return val;
    };
    IdStore.storeIdForKey(Constants.ID_STORE_KEYS.PRODUCT, 'DSW');

    IdStore.storeIdForKey(
        Constants.ID_STORE_KEYS.UTM_SOURCE,
        getNonEmptyVal(
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_SOURCE),
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_SOURCE_G),
            Constants.UTM_DEFAULT.SOURCE,
        ),
    );

    let hourlyPay = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.HOURLY_PAY) || '';
    hourlyPay = Utils.safeAtob(hourlyPay) || '';

    IdStore.storeIdForKey(Constants.ID_STORE_KEYS.HOURLY_PAY, hourlyPay);

    IdStore.storeIdForKey(
        Constants.ID_STORE_KEYS.EXT1,
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.EXT1),
    );

    IdStore.storeIdForKey(
        Constants.ID_STORE_KEYS.EXT2,
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.EXT2),
    );

    IdStore.storeIdForKey(
        Constants.ID_STORE_KEYS.EXT3,
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.EXT3),
    );

    IdStore.storeIdForKey(
        Constants.ID_STORE_KEYS.EXT4,
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.EXT4),
    );

    IdStore.storeIdForKey(
        Constants.ID_STORE_KEYS.EXT5,
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.EXT5),
    );

    IdStore.storeIdForKey(
        Constants.ID_STORE_KEYS.UTM_CAMPAIGN,
        getNonEmptyVal(
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_CAMPAIGN),
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_CAMPAIGN_G),
            Constants.UTM_DEFAULT.MISC,
        ),
    );

    IdStore.storeIdForKey(
        Constants.ID_STORE_KEYS.UTM_MEDIUM,
        getNonEmptyVal(
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_MEDIUM),
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_MEDIUM_G),
            Constants.UTM_DEFAULT.MISC,
        ),
    );
    const ljt = getNonEmptyVal(Utils.getValForKeyFromCurrentUrl('jt'), '');
    let keyword = getNonEmptyVal(
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_TERM),
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_TERM_G),
        '',
    );

    IdStore.storeIdForKey(Constants.ID_STORE_KEYS.LANDING_JT, ljt);
    IdStore.storeIdForKey(Constants.ID_STORE_KEYS.LANDING_KEYWORD, keyword);
    let { jobType } = Utils.getJobType();
    const brand = Utils.brandFromKeyword(keyword);
    const path = window.location.pathname;

    if (brand && path && path.includes('/landing')) {
        keyword = null;
        jobType = brand;
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.JT_KW_MISMATCH, '1');
    }

    if (keyword && !keyword.startsWith('{')) {
        IdStore.storeIdForKey(
            Constants.ID_STORE_KEYS.UTM_TERM,
            keyword ? keyword.split('+').join(' ') : '',
        );
    }

    if (Utils.isEmptyStr(jobType) || Utils.isNull(jobType)) {
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL, Constants.DEFAULT_KW);
    } else {
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL, jobType);
    }

    IdStore.storeIdForKey(
        Constants.URL_ARGS.ADGROUP_ID,
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.ADGROUP_ID),
    );

    IdStore.storeIdForKey(
        Constants.URL_ARGS.PLACEMENT_ID,
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.PLACEMENT_ID),
    );
    IdStore.storeIdForKey(
        Constants.URL_ARGS.CREATIVE_ID,
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.CREATIVE_ID),
    );

    IdStore.storeIdForKey(
        Constants.URL_ARGS.TARGET_ID,
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.TARGET_ID),
    );

    IdStore.storeIdForKey(
        Constants.URL_ARGS.FBCLID,
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.FBCLID),
    );

    IdStore.storeIdForKey(
        Constants.URL_ARGS.CLID,
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.CLID),
    );
    IdStore.storeIdForKey(
        Constants.URL_ARGS.PUBLISHER_SUBID,
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.PUBLISHER_SUBID),
    );

    IdStore.storeIdForKey(
        Constants.URL_ARGS.PUBLISHER_SUB_SOURCE,
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.PUBLISHER_SUB_SOURCE),
    );

    IdStore.storeIdForKey(
        Constants.ID_STORE_KEYS.AD_SOURCE,
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.SOURCE),
    );

    IdStore.storeIdForKey(
        Constants.ID_STORE_KEYS.UTM_CONTENT,
        getNonEmptyVal(
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_CONTENT),
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_CONTENT_G),
            Constants.UTM_DEFAULT.MISC,
        ),
    );

    // Send the http details
    IdStore.storeIdForKey(Constants.ID_STORE_KEYS.REFERRER, document.referrer);
    IdStore.storeIdForKey(Constants.ID_STORE_KEYS.DOMAIN, window.location.host);

    const pDomainValueFromUrl = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.P_DOMAIN);

    if (!Utils.isEmptyStr(pDomainValueFromUrl)) {
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.P_DOMAIN, pDomainValueFromUrl);
    }

    const isSameDomain =
        Utils.isNull(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.P_DOMAIN)) ||
        (IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.P_DOMAIN) &&
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.DOMAIN) &&
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.P_DOMAIN) ===
                IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.DOMAIN));

    IdStore.storeIdForKey(Constants.ID_STORE_KEYS.IS_RDR, isSameDomain ? 0 : 1);

    const oLurl = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.LANDING_URL);
    let newFlow = false;

    if (path && path.includes('/landing')) {
        const lurl = window.location.href.split('#')[0];

        if (lurl !== oLurl) {
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.LANDING_URL, lurl);
            newFlow = true;
        }
    }
    // Create and store a cs-id if doesn't exist already
    const CS_ID = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.CS_ID);

    if (!CS_ID || newFlow) {
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.CS_ID, generateCsId());
    }

    // Set luck orange tags
    const tags = {
        source: IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_SOURCE),
        campaign: IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_CAMPAIGN),
        keyword: IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_TERM),
        jobType: IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL),
        medium: IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_MEDIUM),
    };
    Utils.setLuckyOrangeTags(tags);
    /* Prepping dependency Injection using typedi */
    const profanityFilter = new Filter();
    profanityFilter.removeWords(...['hell', 'hells']);
    Container.set('profanityFilter', profanityFilter);

    SessionHelper.trackVisitCount();
    trackUserStatus();
    IdStore.storeIdForKey(Constants.ID_STORE_KEYS.AB_TEST_30, 'rds-new-ui');
    IdStore.storeIdForKey(Constants.ID_STORE_KEYS.AB_TEST_29, 'rds-new-ui-slot2');

    // initialize configuration
    await ConfigProvider.initialize();
    const medium = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_MEDIUM);

    if (medium && medium.toLowerCase() === 'dow') {
        await OfferWallEntryValidatorHelper.isDirectEntryValid();
    }

    IdStore.storeIdForKey(
        Constants.URL_ARGS.CLID,
        AbTestHelper.fbClidReplacement()
            ? Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.FBCLID)
            : Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.CLID),
    );

    IdStore.storeIdForKey(
        Constants.ID_STORE_KEYS.NEW_ENCODED_SUBID,
        await Utils.getEncodedSubId(
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_SOURCE),
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_CAMPAIGN),
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.PUBLISHER_SUBID),
        ),
    );

    // Load the react component
    ReactDOM.render(
        /*
         * Create a more elaborate error-view based on the below example.
         * - https://github.com/bugsnag/bugsnag-react/blob/master/example/app.js#L105-L107
         */
        <ThemeProvider theme={DefaultTheme}>
            <ErrBoundaryComponent FallbackComponent={ErrorPage}>
                <BrowserRouter>
                    <Routes />
                </BrowserRouter>
            </ErrBoundaryComponent>
        </ThemeProvider>,
        document.getElementById(ReactPlaceholder),
    );
}

initialiseApp();
