import { makeStyles } from '@material-ui/core/styles';

export const useStyles = () =>
    makeStyles(theme => ({
        root: {
            display: 'flex',
            alignItems: 'center',
        },
        container: {
            margin: theme.spacing(2),
            position: 'relative',
        },
        spinner: {
            color: theme.palette.primary.main,
            strokeLinecap: 'round',
        },
    }));
