import { encode, decode } from 'url-safe-base64';
import { CommonHelper } from 'helpers';

const base32 = require('base32');

/**
 * Convert ASCII string into Base64
 * @param {*} str
 */
const encodeBase64 = str => {
    return window.btoa(str);
};

/**
 * Convert Base64 into ASCII string
 * @param {*} str
 */
const decodeBase64 = str => {
    return window.atob(str);
};

/**
 * Url safe encode
 * @param {*} str
 */
const encodeURLSafeBase64 = str => {
    return encode(encodeBase64(str));
};

/**
 * Url Safe decode, encoded with this apps encoder
 * @param {*} str
 */
const decodeURLSafeBase64 = str => {
    return decode(str);
};

/**
 * Url safe encode Base32
 * @param {*} str
 */
const encodeURLSafeBase32 = str => {
    return base32.encode(str);
};

/**
 * Base 32 decode, encoded with this apps encoder
 * @param {*} str
 */
const decodeURLSafeBase32 = str => {
    return base32.decode(str);
};

const cleanTerm = str => {
    if (typeof str === 'undefined' || str === null) return null;

    if (!str) return null;

    if (str.toLowerCase() === 'null') return null;

    let term = str;

    if (term.includes('-')) {
        term = term.split('-').join(' ');
    }

    if (term.includes('+')) {
        term = term.split('+').join(' ');
    }

    return term;
};

const isNull = str => {
    if (CommonHelper.isNull(str)) return true;

    if (!str) return true;
    const stringifiedValue = `${str}`;

    if (stringifiedValue.toLowerCase() === 'null' || stringifiedValue.trim() === '') return true;

    return false;
};

const getCapitalizeStr = str => {
    return str
        ? str
              .split(' ')
              .map(word => {
                  return word ? word[0].toUpperCase() + word.slice(1) : '';
              })
              .join(' ')
        : '';
};

export {
    encodeBase64,
    decodeBase64,
    encodeURLSafeBase64,
    decodeURLSafeBase64,
    cleanTerm,
    isNull,
    getCapitalizeStr,
    encodeURLSafeBase32,
    decodeURLSafeBase32,
};
