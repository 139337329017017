const JobTypes = [
    'walmart',
    'amazon',
    'fedex',
    'walgreens',
    'target',
    'burger king',
    'coca cola',
    'costco',
    'cvs health',
    'goodwill',
    'home depot',
    'kfc',
    'kohls',
    'kroger',
    'macys',
    'marshalls',
    'mcdonalds',
    'moes',
    'panasonic',
    'pepsi',
    'pizza hut',
    'sodexo',
    'starbucks',
    'subway',
    'taco bell',
    'uber',
    'ups',
    'wendys',
    'winn dixie',
    'dollar general',
    'whole foods',
    'whataburger',
    'wawa',
    'usps',
    'union pacific railroad',
    'tractor supply company',
    'sprint',
    'sams club',
    'petsmart',
    'old navy',
    'nike',
    'marriott',
    'lowes',
    'little caesars',
    'kelly services',
    'jack in the box',
    'hobby lobby',
    'hilton',
    'hardees',
    'gamestop',
    'frito lay',
    'ford',
    'foot locker',
    'food lion',
    'five below',
    'dominos pizza',
    'dollar tree',
    'circle k',
    'chick fil a',
    'aramark',
    'aldi',
    '7 eleven',
    'popeyes',
    'family dollar',
    'michaels',
    'ihop',
    'autozone',
    'dunkin donuts',
    'quicktrip',
    'chipotle',
    'staffmark',
    'publix',
    'albertsons',
    'ross stores',
    'dairy queen',
    'biglots',
    'american airlines',
    'shipt',
    'seasonal',
    'delta airlines',
    'jetblue airways',
    'dhl',
    'best buy',
    'ikea',
    'kohler',
    'maxim healthcare',
    'medtronic',
    'united healthcare',
];

export default JobTypes;
