import Utils from './utils';
import { Container } from 'typedi';
import DateUtils from './DateUtils';

export default class UserDataValidator {
    /**
     * @param {string} email
     * @returns {string|null}
     */
    static isValidEmail(email) {
        if (Utils.isEmptyStr(email)) {
            return 'Email cannot be empty';
        }

        if (
            !(
                /^[^\s@]+@[^\s@]+\.[^\s@]{2,5}$/.test(email) &&
                /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(email)
            )
        ) {
            return 'Invalid email';
        }

        return null;
    }

    /**
     * @param {string} firstName
     * @returns {string|null}
     */
    static isValidFirstName(firstName) {
        if (!Utils.minLength(firstName, 1)) {
            return 'First Name has to be greater than 1 character';
        }

        return null;
    }

    static isValidJobType(val) {
        if (false === Utils.minLength(val, 1)) {
            return 'Invalid Job type';
        }

        return null;
    }

    /**
     * @param {string} lastName
     * @returns {string|null}
     */
    static isValidLastName(lastName) {
        if (!Utils.minLength(lastName, 1)) {
            return 'Last Name has to be greater than 1 character';
        }

        return null;
    }

    /**
     *
     * @param {Object} address
     * @returns {string|null}
     */

    static isValidAddressLine(address) {
        if (Utils.isEmptyStr(address)) {
            return 'Address cannot be empty';
        }

        return null;
    }

    /**
     * @param {string} gender
     * @returns {string|null}
     */
    static isValidGender(gender) {
        if (Utils.isEmptyStr(gender)) {
            return 'Gender cannot be empty!';
        } else if (-1 === ['1', '2', '3'].indexOf(gender)) {
            return 'Invalid Gender!';
        }

        return null;
    }

    /**
     * @param {Object} location
     * @returns {string|null}
     */
    static isValidLocation(location) {
        if (Utils.isEmptyObj(location)) {
            return 'Location cannot be empty!';
        }

        return null;
    }

    /**
     * @param {string, string} dob, format
     * @returns {string|null}
     */
    static isValidDob(dob, format) {
        if (Utils.isEmptyStr(dob)) {
            return 'Dob cannot be empty!';
        }

        if (!DateUtils.isValidDateStringForFormat(dob, format)) {
            return 'Invalid DOB';
        }

        return null;
    }

    /**
     * @param {string} zipCode
     * @returns {string|null}
     */
    static isValidZipCode(zipCode) {
        if (!/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipCode)) {
            return 'Invalid zip-code. Zip-code needs to be 5 digits';
        }
    }

    /**
     * @param {string} text
     * @returns {string|null}
     */
    static hasProfanity(text) {
        if (typeof text === 'undefined' || (text && text.length < 0)) return null;
        const filter = Container.get('profanityFilter');
        const cleanText = filter.clean(text);

        if (cleanText !== text) {
            return cleanText;
        }

        return null;
    }

    /**
     * @param {string} fullName
     * @returns {string|null}
     */
    static isValidFullName(name) {
        const trimmedName = name.trim();
        const pattern = /[`.!@#$%^&*()_+\-=[]{};':"\\|,.<>\/?~]/;

        if (
            !Utils.minLength(trimmedName, 3) ||
            pattern.test(trimmedName) ||
            trimmedName.split(' ').length < 2
        ) {
            return 'Please enter a valid name';
        }

        return null;
    }

    static isValidStreet(val) {
        if (false === Utils.minLength(val, 1)) {
            return 'Street name has to be greater than 1 character';
        }

        return null;
    }

    static isValidState(val) {
        if (false === Utils.minLength(val, 1)) {
            return 'State has to be greater than 1 character';
        }

        return null;
    }

    static isValidCity(val) {
        if (false === Utils.minLength(val, 1)) {
            return 'City has to be greater than 1 character';
        }

        return null;
    }
}
