import UserDataValidator from 'common/userDataValidator';
import PhoneValidator from 'common/PhoneValidator';
import UserDetailsDataSource from 'datasource/userDetailsDataSource';

const validatorFn = (fnName, value) => {
    const success = ((fnNameCheck, valueCheck) => {
        if (!valueCheck) {
            return true;
        }

        switch (fnNameCheck) {
            case 'isValidDob':
                return UserDataValidator.isValidDob(valueCheck) ? false : true;
            case 'isValidFirstName':
                return UserDataValidator.isValidFirstName(valueCheck) ? false : true;
            case 'isValidLastName':
                return UserDataValidator.isValidLastName(valueCheck) ? false : true;
            case 'isValidPhoneNumber':
                return PhoneValidator.isValidPhoneNumber(valueCheck) ? false : true;
            case 'isValidState':
                return UserDataValidator.isValidState(valueCheck) ? false : true;
            case 'isValidStreet':
                return UserDataValidator.isValidStreet(valueCheck) ? false : true;
            case 'isValidCity':
                return UserDataValidator.isValidCity(valueCheck) ? false : true;
            case 'isValidZipCode':
                return UserDataValidator.isValidZipCode(valueCheck) ? false : true;
            case 'isValidEmail':
                return UserDataValidator.isValidEmail(valueCheck) ? false : true;
            default:
                return true;
        }
    })(fnName, value);

    return success;
};

const verifyPhoneNumber = (phone, onSuccess, onFailed) => {
    try {
        UserDetailsDataSource.validatePhone(phone)
            .then(response => {
                if (response && response.verified) {
                    onSuccess();
                } else {
                    onFailed();
                }
            })
            .catch(error => console.error(error));
    } catch (err) {
        console.error(err);
    }
};

export { validatorFn, verifyPhoneNumber };
