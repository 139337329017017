import { STATE_LISTS } from './stateLists';

export const main = state => {
    if (!state) return STATE_LISTS;
    state = state.split('.').join('');
    const found = STATE_LISTS.find(
        item =>
            item.name.toUpperCase() === state.toUpperCase() ||
            item.usps.toUpperCase() === state.toUpperCase() ||
            item.altAbbr.find(st => st.toUpperCase() === state.toUpperCase()),
    );

    if (found) return found;

    return 'No state found!';
};

export const abbr = state => {
    if (!state) return 'Please pass a full state name as your argument';
    const found = STATE_LISTS.find(item => item.name.toUpperCase() === state.toUpperCase());

    if (found) return found.usps;

    return 'No abbreviation found with that state name';
};

export const fullName = abbr => {
    if (!abbr) return 'Please pass an abbreviation as your argument';
    const found = STATE_LISTS.find(
        item =>
            item.usps.toUpperCase() === abbr.toUpperCase() ||
            item.altAbbr.find(st => st.toUpperCase() === abbr.toUpperCase()),
    );

    if (found) return found.name;

    return 'No state found with that abbreviation';
};

export const only50 = () => {
    const notStates = ['DC', 'AS', 'GU', 'MP', 'PR', 'VI', 'UM'];

    return STATE_LISTS.filter(item => !notStates.includes(item.usps));
};

export const demonym = state => {
    if (!state) return 'Please pass a state name or abbreviation as your argument';
    const found = STATE_LISTS.find(
        item =>
            item.name.toUpperCase() === state.toUpperCase() ||
            item.usps.toUpperCase() === state.toUpperCase() ||
            item.altAbbr.find(st => st.toUpperCase() === state.toUpperCase()),
    );

    if (found) return found.demonym;

    return 'No demonym found for that state';
};
