/* eslint-disable max-lines */
/* eslint-disable no-irregular-whitespace */
import { getFlavor } from 'flavors.macro';

const flavor = getFlavor('layout-theme');
module.exports = {
    SINGLE_VALUED: 'single value',
    MULTI_VALUED: 'multi value',
    VALID_DATE_FORMATS: [
        'M-D-YYYY',
        'D-M-YYYY',
        'YYYY-M-D',
        'YYYY-D-M',
        'M/D/YYYY',
        'D/M/YYYY',
        'YYYY/M/D',
        'YYYY/D/M',
    ],
    DEFAULT_NETWORK_TIMEOUT_MS: 10000,
    DEFAULT_SURVEY_TITLE: 'Survey',
    DEFAULT_KW: 'Full Time Jobs',
    DEFAULT_INVALID_EMAIL_ATTEMPTS: 4,
    THEME: {
        MYJOBSCORNER: 'mjc',
        MYJOBSCORNERV2: 'mjcv2',
        GREEN: 'green',
        BLUE: 'blue',
    },
    NAME_ON_CARD_LENGTH: 13,
    EDU: {
        V2: 'dropdown-test-v2',
        V1: 'controlv2',
    },
    ALLOWED_FLAVORS: {
        REALDEALSWEEPS: 'realDealSweeps',
    },
    /*
     * UPWARDRG: {
     *     V2: 'upward_rg_v2',
     *     V1: 'upward_rg_v1'
     * },
     */
    PATH_ARGS: {
        LANDER_NAME: 'landerName',
        LANDER_PATH_NAME: 'landerPathName',
    },
    URL_ARGS: {
        SURVEY_ID: 'sid',
        SURVEY_PATH_ID: 'spid',
        USER_ID: 'uid',
        UTM_SOURCE: 'utm_source',
        UTM_MEDIUM: 'utm_medium',
        UTM_CAMPAIGN: 'utm_campaign',
        UTM_TERM: 'utm_term',
        UTM_CONTENT: 'utm_content',
        UTM_SOURCE_G: 'p',
        UTM_MEDIUM_G: 'ch',
        UTM_CAMPAIGN_G: 'cm',
        UTM_TERM_G: 'kw',
        UTM_CONTENT_G: 'cnt',
        PREVIEW: 'preview',
        ADGROUP_ID: 'ag',
        CREATIVE_ID: 'cr',
        PLACEMENT_ID: 'pl',
        TARGET_ID: 'tid',
        FBCLID: 'fbclid',
        CLID: 'clid',
        FNAME: 'pifnm',
        LNAME: 'pilnm',
        GENDER: 'pigd',
        DOB: 'pibd',
        ADDRESS: 'piaddr',
        PHONE: 'piphnm',
        EMAIL: 'piem',
        SHOW_OTHER_GENDER_OPTION: 'ogen',
        DISABLE_REG2_OPT_IN: 'dr2o',
        SHOW_INTERSTITIAL_ADS: 'shw_intrtial_ads',
        HOURLY_PAY: 'hp',
        PUBLISHER_SUBID: 'ps',
        PUBLISHER_SUB_SOURCE: 'pss',
        JOB_DETAILS: 'jb_details',
        SOURCE: 'source',
        ZIP: 'z',
        ENCRYPTED_EMAIL: 'pieem',
        INITIAL_VECTOR: 'pieiv',
        EVERFLOW_TRANSACTION_ID: 'etid',
        GIFTCARD_ID: 'giftCardId',
        SURL_TOKEN: 'surl',
        P_DOMAIN: 'pdomain',
        MULTI_BRAND_LOGO: 'l',
        BASE_API_URL: 'BASE_API_URL',
        PUBLISHER_COST_BASE_URL: 'PUBLISHER_COST_BASE_URL',
        CONFIGURATION_BASE_URL: 'CONFIGURATION_BASE_URL',
        PROXY_PROVIDER_URL: 'PROXY_PROVIDER',
        GIFT_CARD_SERVICE_BASE_URL: 'GIFT_CARD_SERVICE_BASE_URL',
        EXT1: 'ext1',
        EXT2: 'ext2',
        EXT3: 'ext3',
        EXT4: 'ext4',
        EXT5: 'ext5',
    },
    ID_STORE_KEYS: {
        PRODUCT: 'product',
        LANDING_KEYWORD: 'lkw',
        LANDING_JT: 'ljt',
        LANDING_URL: 'lurl',
        JT_KW_MISMATCH: 'jtkw_mis',
        BACK_INTERSTITIAL: 'bck_int',
        CURRENT_APP: 'current_app',
        AD_SOURCE: 'ad_source',
        REG2_TRUSTED_FORM_CERT_URL: 'reg2_tf_curl',
        REG2_LEADID: 'reg2_leadid',
        PENTIUS_PREPING: 'pentius_preping',
        JOBCASE_PREPING: 'jobcase_preping',
        DVM_DIABETES_PREPING: 'dvm_diabetes_preping',
        ADVISIO_MEDICARE_PREPING: 'advisio_medicare_preping',
        EQUOTE_SSDI: 'eQuotes_ssdi',
        EQUOTE_SSDI_PREPING: 'eQuotes_ssdi_preping',
        LIGHT_FIRE_AI_PREPING: 'light_fire_ai_preping',
        FLATIRON_MEDIA_PREPING: 'flatiron_preping',
        REWARD_ADVISOR_PREPING: 'reward_advisor_preping',
        ARROYO_MEDIA_PREPING: 'arroyo_media_preping',
        INB_ACCIDENT_PREPING: 'inb_accident_preping',
        INB_LAWSUITE_PREPING: 'inb_lawsuite_preping',
        INB_DEBT_PREPING: 'inb_debt_preping',
        LIGHT_FIRE_AI: 'light_fire_ai',
        LIGHT_FIRE_AI_2: 'light_fire_ai_#2',
        LIGHT_FIRE_AI_PREPING_2: 'light_fire_ai_preping_#2',
        SUBJECTWELL_CLINIC_TRIAL: 'subject_well_clinic_trial',
        SUBJECTWELL_CLINIC_TRIAL_PREPING: 'subject_well_clinic_trial_preping',
        SUBJECTWELL_CLINIC_TRIAL_CONDITION: 'subject_well_clinic_trial_condition',
        ADQUIRE_RJR_PREPING: 'adquire_rjr_preping',
        ADQUIRE_RJR_SWEEPS: 'adquire_rjr_sweeps',
        DVM_DIABETES_SWEEPS: 'dvm_diabetes_sweeps',
        DVM_ENERGY_SWEEPS: 'dvm_energy_sweeps',
        DVM_ENERGY_PREPING: 'dvm_energy_preping',
        DVM_CA_PREPING: 'dvm_ca_preping',
        DVM_CA_SWEEPS: 'dvm_ca_sweeps',
        PUZZ_PREPING: 'puzz_preping',
        PUZZ_SWEEPS: 'puzz_sweeps',
        BRINKS_MONEY_SWEEPS: 'brinks_money_sweeps',
        GENETIC_CARDIO_SWEEPS_PREPING: 'genetic_cardio_sweeps_preping',
        PGX_SWEEPS_PREPING: 'pgx_sweeps_preping',
        NEUROLOGICAL_GENETIC_PREPING: 'neurological_genetic_preping',
        ZANTAC_SWEEPS_PREPING: 'zantac_sweeps_preping',
        ROUNDUP_SWEEPS_PREPING: 'roundup_sweeps_preping',
        GENETIC_CARDIO_SWEEPS_TOKEN: 'genetic_cardio_sweeps_token',
        PGX_SWEEPS_TOKEN: 'pgx_sweeps_token',
        NEUROLOGICAL_GENETIC_TOKEN: 'Neurological_Genetic_token',
        ZANTAC_SWEEPS_TOKEN: 'zantac_sweeps_token',
        ROUNDUP_SWEEPS_TOKEN: 'roundup_sweeps_token',
        GENETIC_CARDIO_SWEEPS: 'genetic_cardio_sweeps',
        PGX_SWEEPS: 'pgx_sweeps',
        NEUROLOGICAL_GENETIC: 'neurological_genetic',
        ZANTAC_SWEEPS: 'zantac_sweeps',
        ROUNDUP_SWEEPS: 'roundup_sweeps',
        CONVERZATE_HERNIA_MESH: 'converzate_hernia_mesh',
        CONVERZATE_HERNIA_MESH_TOKEN: 'converzate_hernia_mesh_token',
        CONVERZATE_HERNIA_MESH_PREPING: 'converzate_hernia_mesh_preping',
        CONVERZATE_3M_EARPLUG_PREPING: 'converzate_3m_earplug_preping',
        CONVERZATE_3M_EARPLUG_TOKEN: 'converzate_3m_earplug_token',
        CONVERZATE_3M_EARPLUG: 'converzate_3m_earplug',
        CONVERZATE_TOXIC_BABY_PREPING: 'converzate_toxic_baby_preping',
        CONVERZATE_TOXIC_BABY_TOKEN: 'converzate_toxic_baby_token',
        CONVERZATE_TOXIC_BABY: 'converzate_toxic_baby',
        BRINKS_MONEY_SWEEPS_CAMPAIGN_RESPONDING: 'brinks_money_campaign_active',
        IST_MEDIA_SAMPLES_PREPING: 'ist_media_samples_preping',
        IST_MEDIA_AMERICAN_PREPING: 'ist_media_american_preping',
        IST_MEDIA_SURVEY_PREPING: 'ist_media_survey_preping',
        CONVERZATE_PHILIP_CANCER_PREPING: 'converzate_philip_cancer_preping',
        CONVERZATE_PHILIP_CANCER_TOKEN: 'converzate_philip_cancer_token',
        CONVERZATE_PHILIP_CANCER: 'converzate_philip_cancer_baby',
        PUBLIC_IP: 'pipv4',
        SESSION_ID: 'sid',
        DEVICE_ID: 'did',
        LANDER_ID: 'lid',
        LANDER_NAME: 'lname',
        LANDER_PATH_NAME: 'lpath_name',
        FLAVOR_NAME: 'flv_name',
        USER_ID: 'uid',
        SURVEY_ID: 'svid',
        SURVEY_PATH_ID: 'spid',
        JOB_TYPE_VAL: 'job_type',
        GEO_CITY: 'city',
        GEO_COUNTRY: 'country',
        GEO_REGION: 'region',
        GEO_ZIP_CODE: 'zip',
        GEO_IP: 'geo_ip',
        UTM_SOURCE: 'utm_source',
        UTM_MEDIUM: 'utm_medium',
        UTM_CAMPAIGN: 'utm_campaign',
        UTM_TERM: 'utm_term',
        UTM_CONTENT: 'utm_content',
        REFERRER: 'referrer',
        DOMAIN: 'domain',
        SURVEY_DATA_PREFIX: '__survey_data',
        CS_ID: 'cs_id',
        USER_RESP_HASH_ID: 'user_resp_hash',
        SUB_DATA_KEY: 'survey_sub_key',
        USER_DETAILS: `${flavor}_user_details`,
        QUESTION_NUMBER: 'qno',
        QUESTION_NAME: 'qnm',
        AD_SPOT_NUMBER: 'spotno',
        EMAIL_RESETTED: 'email_resetted',
        UUID: 'uuid',
        EMAIL_VERIFICATION: 'evs',
        PHONE_VERIFICATION: 'pvs',
        CAMPAIGN_BLACKLIST: 'campaign_blacklist',
        JOB_ADS_SOURCE: 'jad',
        PAGE_REFRESH: 'pg_refresh',
        AB_TEST: 'abt',
        AB_TEST_1: 'abt1',
        AB_TEST_2: 'abt2',
        AB_TEST_3: 'abt3',
        AB_TEST_4: 'abt4',
        AB_TEST_5: 'abt5',
        AB_TEST_6: 'abt6',
        AB_TEST_7: 'abt7',
        AB_TEST_8: 'abt8',
        AB_TEST_9: 'abt9',
        AB_TEST_10: 'abt10',
        AB_TEST_11: 'abt11',
        AB_TEST_12: 'abt12',
        AB_TEST_13: 'abt13',
        AB_TEST_14: 'abt14',
        AB_TEST_15: 'abt15',
        AB_TEST_16: 'abt16',
        AB_TEST_17: 'abt17',
        AB_TEST_18: 'abt18',
        AB_TEST_19: 'abt19',
        AB_TEST_20: 'abt20',
        AB_TEST_21: 'abt21',
        AB_TEST_22: 'abt22',
        AB_TEST_23: 'abt23',
        AB_TEST_24: 'abt24',
        AB_TEST_25: 'abt25',
        AB_TEST_26: 'abt26',
        AB_TEST_27: 'abt27',
        AB_TEST_28: 'abt28',
        AB_TEST_29: 'abt29',
        AB_TEST_30: 'abt30',
        AB_TEST_31: 'abt31',
        AB_TEST_32: 'abt32',
        AB_TEST_33: 'abt33',
        AB_TEST_34: 'abt34',
        AB_TEST_35: 'abt35',
        AB_TEST_36: 'abt36',
        AB_TEST_37: 'abt37',
        AB_TEST_38: 'abt38',
        AB_TEST_39: 'abt39',
        AB_TEST_40: 'abt40',
        AB_TEST_41: 'abt41',
        AB_TEST_42: 'abt42',
        AB_TEST_43: 'abt43',
        AB_TEST_44: 'abt44',
        AB_TEST_45: 'abt45',
        AB_TEST_46: 'abt46',
        AB_TEST_47: 'abt47',
        AB_TEST_48: 'abt48',
        AB_TEST_49: 'abt49',
        AB_TEST_50: 'abt50',
        DEVICE_WIDTH: 'w',
        DEVICE_HEIGHT: 'h',
        DEVICE_PIXEL_RATIO: 'dpr',
        PAGE_URL: 'purl',
        JOB_DETAILS: 'jb_details',
        DYNAMIC_XML_FEED: 'dyn_feed',
        THEME_COLOR: `${flavor}_theme_color`,
        THEME_GRADIENT: `${flavor}_theme_grad`,
        THEME_TEXT_COLOR: `${flavor}_theme_txtcolor`,
        THEME_BORDER: `${flavor}_theme_border`,
        THEME_BORDER_RADIUS: `${flavor}_theme_borderradius`,
        THEME_FONT_SIZE: `${flavor}_theme_fontsize`,
        THEME_FONT_WEIGHT: `${flavor}_theme_fontweight`,
        THEME_HEIGHT: `${flavor}_theme_height`,
        THEME_WIDTH: `${flavor}_theme_width`,
        THEME_PRIMARY_COLOR: `${flavor}_primary_theme_color`,
        THEME_PRIMARY_CONTRAST_TEXT_COLOR: `${flavor}_primary_text_color`,
        THEME_SECONDARY_COLOR: `${flavor}_secondary_theme_color`,
        THEME_SECONDARY_CONTRAST_TEXT_COLOR: `${flavor}_secondary_text_color`,
        THEME_DEFAULT_BACKGROUND_COLOR: `${flavor}_background_color`,
        PUBLISHER_SUBID: 'ps',
        PUBLISHER_SUB_SOURCE: 'pss',
        UPWARDRGFLOW: 'upward_rg',
        EXIT_INTERSTITIAL_JOBS: 'ext_intstl_jb',
        EVERFLOW_TRANSACTION_ID: 'etid',
        LANDING_FLOW: 'landing_flow',
        PROVIDER_DATA: 'provider_data',
        FIRST_VISIT: 'first_visit',
        LAST_VISIT: 'last_visit',
        EMAIL_SUB: 'email_sub',
        PHONE_SUB: 'phone_sub',
        CRISP_HEALTHCARE_OPTION_MEDICARE: 'crisp_healthcare_option_medicare',
        GIFT_CARDS: 'gcs_giftCards',
        FILTERED_GIFT_CARDS: 'filtered_gift_cards',
        GIFTCARD_ID: 'giftcardid',
        GIFT_CARD_DETAILS: 'giftCardDetails',
        GIFT_CARD_GOLD_SKIP_COUNT: 'giftCardGoldSkipCount',
        GIFT_CARD_PLATINUM_SKIP_COUNT: 'giftCardPlatinumSkipCount',
        GIFT_CARD_DIAMOND_SKIP_COUNT: 'giftCardDiamondSkipCount',
        FEATURE_SETS: 'feature_sets',
        CONFIG_SCOPES_LOADED: 'config_scopes_loaded',
        P_DOMAIN: 'pdomain',
        IS_RDR: 'isrdr',
        VISIT_COUNT: 'visitCount',
        REGISTERED_VISIT_COUNT: 'registered_visit_count',
        HNP_CATEGORY_LIST: 'hnp_category_list',
        SURVEY_REQUEST_ID: 'survey_request_id',
        SURVEY_DATA_FOR_AD: 'survey_data_for_ad',
        EMAIL_OPT_IN_TS: 'email_optin_ts',
        PHONE_OPT_IN_TS: 'phone_no_optin_ts',
        OFFER_PATH_BACK_BUTTON_INDEX: 'offer_path_back_index',
        BACK_TO_OFFERS: 'back_to_offers',
        USER_STATUS: 'user_status',
        USER_STATUS_CURRENT: 'user_status_current',
        USER_STATUS_IS_SUBSCRIBED: 'is_sub',
        USER_STATUS_IS_REGISTERED: 'is_reg',
        USER_EMAIL: 'user_email',
        FIRE_LANDING_IMP_ON_GRAT: 'FIRE_LANDING_IMP_ON_GRAT',
        MULTI_BRAND_LOGO: 'logos',
        IS_EMAIL_BLACK_LISTED: 'is_email_bl',
        EXT1: 'ext1',
        EXT2: 'ext2',
        EXT3: 'ext3',
        EXT4: 'ext4',
        EXT5: 'ext5',
        HOURLY_PAY: 'hp',
        BASE_API_URL: 'BASE_API_URL',
        PUBLISHER_COST_BASE_URL: 'PUBLISHER_COST_BASE_URL',
        CONFIGURATION_BASE_URL: 'CONFIGURATION_BASE_URL',
        PROXY_PROVIDER_URL: 'PROXY_PROVIDER',
        GIFT_CARD_SERVICE_BASE_URL: 'GIFT_CARD_SERVICE_BASE_URL',
        ASA_PIXEL: 'ASA_PIXEL',
        INIT_EMAIL: 'init_email',
        UTM_CAMPAIGN_BASE64: 'utm_campaign_base64',
        REG2_TCPA_SKIPPED: 'reg2_tcpa_skipped',
        PUB_SOURCE_TYPE_CONFIG: 'pub_source_type_config',
        ABT_EXP: 'abt_exp',
        NEW_ENCODED_SUBID: 'NEW_ENCODED_SUBID',
        ENCODED_SOURCE: 'encoded_source_str',
        PUB_SOURCE_TYPE: 'pub_source_type',
    },
    WEBSTORE_CONSTANTS: {
        SYNC: 0,
        ASYNC: 1,
    },
    CREATIVE_REPORTING_EVENTS: {
        OW_PAGE_IMP: 'ow_page_imp',
        OW_ITEM_IMP: 'ow_item_imp',
        OW_ITEM_CLK: 'ow_item_clk',
    },
    EVENT: {
        NON_US_SKIP: 'non_us_skip',
        SURVEY_QUESTION_ANSWERED: 'sv_ans',
        LANDING_PAGE_IMP: 'lp_imp',
        PRE_LANDING_PAGE_IMP: 'pl_imp',
        USER_REGISTRATION_COMPLETE: 'usr_reg',
        SURVEY_PATH_IMP: 'sp_imp',
        SURVEY_QUESTION_IMP: 'sv_ques_imp',
        SURVEY_PAGE_IMP: 'sv_imp',
        SURVEY_COMPLETE: 'sv_comp',
        HNP_IMP: 'hnp_imp',
        HNP_SKIP: 'hnp_skip',
        HNP_LEAD: 'hnp_lead',
        HNP_LEAD_SENT: 'hnp_lead_send',
        HNP_LEAD_SUCCESS: 'hnp_lead_success',
        HNP_LEAD_FAIL: 'hnp_lead_failure',
        AD_LEAD: 'ad_lead',
        ADS_IMP: 'ad_imp',
        AD_PATH_IMP: 'ad_path_imp',
        AD_PROG: 'ad_prog',
        AD_CLICK: 'ad_clk',
        MAX_CLICK: 'mx_clk',
        AD_SKIP: 'ad_skip',
        AD_FAILED: 'slot_failed',
        AD_IMP_V: 'slot_imp_v',
        AD_PATH_COMPLETED: 'ad_path_cmp',
        GRATIFICATION_PAGE_IMP: 'grat_imp',
        GRATIFICATION_AVAIL: 'grat_avail',
        USER_REGISTRATION_SKIP: 'skip',
        ERROR: 'error',
        REVENUE: 'ervn',
        EXPLORE_JOBS_BUTTON_SUB: 'explore_jobs_btn_sub',
        USER_DETAIL_CONTINUE_BUTTON_SUB: 'user_detail_continue_btn_sub',
        ZIP_ADDED: 'zip_sub',
        JOB_TYPE_ADDED: 'job_type_sub',
        EMAIL_ADDED: 'email_sub',
        SWEEPS_EMAIL: 'sweeps_email',
        SWEEPS_REG2: 'sweeps_reg2',
        LANDER_SUBMIT: 'enter_now_btn_sub',
        FIRSTNAME_ADDED: 'firstname_sub',
        LASTNAME_ADDED: 'lastname_sub',
        REPLACE_USER: 'replace_user',
        REG_1_SUB: 'reg1_sub',
        RVN_EVENT_NAME: 'rvn',
        ADLEAD_STATUS_EVENT: 'adlead_status',
        OFFER_CLICK: 'offer_path_clicked',
        CREATIVE_EVENT_FIRED: 'creativeEventFired',
        SHOW_SKIP_BUTTON: 'show_skip_button',
        IFRAME_CONTENT_CHANGED: 'iframe_content_changed',
        EMAIL_VERIFICATION_FAILED: 'ev_failed',
        EMAIL_VERIFICATION_API_FAILED: 'ev_ap_failed',
        ZIP_SKIP: 'zip_skip',
        PAGE_REFRESH: 'pg_refresh',
        PAGE_BACK: 'pg_back',
        REG1_SUB: 'reg1_sub',
        REG1_CTA_FAILED: 'reg1_cta_failed',
        REG2_CTA_FAILED: 'reg2_cta_failed',
        REG3_CTA_FAILED: 'reg3_cta_failed',
        UUID_MISSING: 'uuid_missing',
        GENDER_PREDICTED: 'gen_p',
        GENDER_PREDICTION_USED: 'gen_p_u',
        GENDER_SELECTED: 'gen_s',
        SMS_OPT_IN_EVENT: 'sms_optin',
        AUTO_SKIP: 'auto_skip',
        SLOT_LOAD_FAILED: 'slot_l_fs',
        DYN_XML_POPOUT: 'dxml_reg1_popout',
        KEYWORD_SELECT: 'kw_select',
        GRATIFICATION_ROTATED: 'grat_advertiser_changed',
        OFFER_PATH_NEXT: 'offer_path_next_clicked',
        OFFER_PATH_NAVIGATE: 'offer_path_next_navigated',
        XML_PRE_LANDER: 'xml_pre_lander',
        INTERSTITIAL_IMP: 'interstitial_imp',
        INTERSTITIAL_CLOSE: 'interstitial_close',
        REG2_CTA_CLICK: 'reg2_click',
        EMAIL_SUB_LISTINGS: 'email_sub_listings',
        PHONE_SUB_LISTINGS: 'phone_sub_listings',
        SWEEPS_ENTRY: 'sweeps_entry',
        ENTRY_CONFIRMED: 'entry_confirmed',
        PREPING_SEND: 'preping_send',
        PREPING_SUCCESS: 'preping_success',
        PREPING_FAILURE: 'preping_failure',
        REG_IMP: 'reg_imp',
        ZIP_FAILED: 'zip_failed',
        REG_SKIP: 'reg_skip',
        USER_REG: 'usr_reg',
        CONFIRM_IMP: 'confirm_imp',
        EVRN: 'ervn',
        EMAIL_SUB_IMP: 'email_sub_imp',
        JOURNEY_INTR_IMP: 'journey_intr_imp',
        OFFER_WALL_IMP: 'ow_imp',
        DEAL_IMP: 'deal_imp',
        DEAL_FAILED: 'deal_failed',
        DEAL_IMP_V: 'deal_imp_v',
        DEAL_IMP_SHOWN: 'deal_intr_shown',
        DEAL_PROG: 'deal_prog',
        ALERT_INTR_IMP: 'alert_intr_imp',
        DEAL_CTA_CLK: 'deal_cta_clk',
        DEAL_CLK: 'deal_clk',
        BACK_TO_OFFER_WALL: 'back_to_offerwall',
        OFFER_WALL_CONTINUE: 'ow_cont',
        OFFER_WALL_INTR_CONT: 'ow_intr_continue',
        OFFER_WALL_INTR_BACK: 'ow_intr_back',
        REWARD_VIEW_CLICK: 'rw_clk',
        REWARD_EMAIL_SUB: 'rw_email_sub',
        REWARD_IMP: 'rw_imp',
        UNSUBSCRIBE: 'unsubscribe',
        REWARD_TO_DEALS: 'reward2deals',
        DIRECT_OFFERS: 'dow_imp',
        PHONE_VERIFICATION_VERIFIED: 'pv_verified',
        PHONE_VERIFICATION_NOT_VERIFIED: 'pv_failed',
        SURVEY_PATH_FAILED: 'sp_failure',
        SPIN_CLK_1: 'spin_1_clk',
        SPIN_CLK_2: 'spin_2_clk',
        SPIN_CLK_3: 'spin_3_clk',
        SPIN_CLK_SUCCESS: 'spin_success_clk',
        OFFER_WALL_SKIP: 'showmedeals_skip',
        BACK_TO_BUTTON_CLICK: 'pg_back_clk',
        SWEEPS_USER: 'sweeps_user',
        TF_CURL: 'tf_curl',
        LEAD_ID: 'leadid',
        REPEAT_USER_STATUS_CHANGE: 'repeat_user_status_change',
        SPAM_PIXEL_FIRED: 'spam_pixel_fired',
    },
    AB_TEST: {
        INTERSTITIAL_VARIATION: 'interstitial1',
    },
    EVENT_TYPE: {
        LOCAL_STORAGE_NOT_ACCESSIBLE: 'local_storage_excp',
        SESSION_STORAGE_NOT_ACCESSIBLE: 'session_storage_excp',
        LANDING: 'landing',
        REGISTRATION: 'registration',
        SURVEY: 'survey',
        ADS: 'ads',
        GRAITIFICATION: 'gratification',
        SURVEY_PATH: 'survey_path',
        DATA: 'data',
        LANDING_INTERSTITIAL: 'landing_interstitial',
        CONFIRMATION: 'confirmation',
        OFFER_WALL: 'deals',
        REWARD: 'reward',
        PATH_OFFER_WALL: 'showmedeals',
        DETAILS_CONFIRMATION: 'details_confirmation',
    },

    CONFIG: {
        DEFAULT_PREPROCESS_SURVEY_TITLE: false,
        DEFAULT_SURVEY_SHOW_COMPLETED_SURVEY: false,
        DEFAULT_ALLOW_COOKIES_IN_CORS: true,
    },
    PAGE_ENDPOINTS: {
        LANDING: '/landing',
        SURVEY: '/survey',
        ADS: '/offers',
        GRATIFICATION: '/listings',
        HOME: '/home',
        SUBSCRIBE: '/subscribe',
        UNSUBSCRIBE: '/unsubscribe',
        DEFAULT_SEARCH_PARAM: '?sid=60',
        DEFAULT_SEARCH_PARAM_STAG: '?sid=100',
        CONTACTUS: '/contactus',
        TERMSANDCONDITIONS: '/terms-and-conditions',
        PRIVACY_POLICY: '/privacy-policy',
        CCPA: '/california-privacy-notice',
        ARTICLE: '/articles/:articleId',
        FINDJOBS: '/findjobs',
        ENTRY_CONFIRMATION: '/entry',
        OFFICIAL_RULES: '/official-rules',
        ENTRY_EMAIL_CONFIRMATION: '/confirm',
        ALTERNATE_ENTRY: '/alternate-entry',
        GIFTOFFER: '/gift-offer',
        REGISTRATION: '/registration',
        SUBMISSION: '/submission',
        TRANSITION: '/transition',
        OFFERWALL: '/offerwall',
        REWARD_STATUS: '/reward-status',
        CONFIRMATION: '/confirmation',
        DEAL: '/offers',
        OFFER_WALL: '/offerpage',
        DETAILS_CONFIRMATION: '/confirm-details',
        SHOW_ME_DEAL: '/showmedeals',
        ENTRYCONFIRMATION: '/entry',
        STAND_ALONE_PAGE: '/saofferpage',
    },
    DOM_EVENTS: {
        ON_APP_MOUNTED: 'onAppLoaded',
        SURVEY_NUM_QUESTIONS: 'numQuestionsInSurvey',
        SURVEY_COMPLETED: 'surveyCompleted',
        SURVEY_QUESTION_ANSWERED: 'surveyQuestionAnswered',
        ON_USER_DATA_PROCESSOR_CB_CREATED: 'onUserDataProcessorCbCreated',
        IFRAME_RESIZE: 'resize',
        CREATIVE_FAILURE: 'creativeFailure',
        CREATIVE_SUCCESS: 'creativeSuccess',
    },
    INTERNAL_EVENTS: {
        ON_SESSION_FETCHED: 'sessionFetched',
    },
    PREPROCESSOR: {
        /**
         * List of all the keys that contains an expression that may contain the question-id
         */
        QUESTION_PREFIX_EXPRESSION_FIELDS: ['visibleIf', 'enableIf', 'expression'],
    },
    USER: {
        STORE_USER_KEY: 'user_details',
        DOB_USER_INPUT_DATE_FORMAT: 'YYYY-MM-DD',
        DOB_API_DATE_FORMAT: 'MM/DD/YYYY',
        DOB_API_DAY_FORMAT: 'DD/MM/YYYY',
        STORE_REGISTERED_USER_KEY: 'registered_user',
        EMAILS_SUBMITTED: 'sub_emails',
        EMPLOYER_TYPE_VAL: 'employer_type',
    },
    USER_DETAILS_KEY: {
        EMAIL: 'email',
        DOB: 'dob',
        ZIP_CODE: 'zip',
        FIRST_NAME: 'first_name',
        LAST_NAME: 'last_name',
        PHONE: 'phone_number',
        ADDRESS: 'address',
        GENDER: 'gender',
    },
    CONSUMER_AWARENESS_GROUP_OFFERS: {
        'CAG Elmiron': {
            text: 'Suffered eye damage after taking the bladder medicine Elmiron',
            category: 'CAG_Elmiron',
        },
        'CAG Hernia Mesh': {
            text: 'A Hernia Mesh implant with complications',
            category: 'CAG_Hernia_Mesh',
        },
        'CAG Talcum Powder': {
            text: 'Diagnosed with cancer after using Baby Powder/Talcum Powder',
            category: 'CAG_Talcum_Powder',
        },
        'CAG 3M Earplug': {
            text:
                'Suffered hearing loss or tinnitus (ringing in the ear) after using combat earplugs in the military between 2003-2015',
            category: 'CAG_3M_Earplug',
        },
        'CAG Zantac': {
            text: 'Been diagnosed with cancer after using the Heartburn Medication Zantac',
            category: 'CAG_Zantac',
        },
        'CAG Clergy Abuse': {
            text: 'Been sexually abused by a member of the clergy',
            category: 'CAG_Clergy_Abuse',
        },
        'CAG Baby Formula': {
            text: 'Suffered side effects after being born premature and given baby formula',
            category: 'CAG_Baby_Formula',
        },
    },
    CONSUMER_AWARENESS_GROUP_OFFERS_TCPA: `By clicking the ‘submit’ button, I consent to be contacted at the number provided to Injury Review, as well as, an attorney/law firm, third-party verification service or a pre-recorded call, which may or may not include an auto-dialer or an SMS text message. I consent even if my phone number provided is registered on the Federal or State “Do Not Call Registry”. I acknowledge that standard message and data rates apply. Clicking ‘submit’ constitutes my electronic signature for my authorization to being contacted and my agreement to the Terms and Conditions thereof. This authorization is not required to purchase goods or services.`,
    CONSUMER_AWARENESS_GROUP_OFFERS_ERROR: `Please select at least an option`,
    ADS: {
        /*eslint-disable */
        DUMMY_AD_CODE:
            '<script id="mNCC" language="javascript">medianet_width="328";medianet_height="250";medianet_crid="571076646";medianet_versionId="3111299";medianet_requrl="https://mxplayer.in/";</script> <script src="https://contextual.media.net/nmedianet.js?cid=8CU3O41G4"></script>',
        /* eslint-enable */
        RESP_KEYS: {
            OFFERPATH_ID: 'opid',
            AD_ID: 'advId',
            ADVERTISER_NAME: 'advertiserName',
            CAMPAIGN_ID: 'campaignId',
            CAMPAIGN_NAME: 'campaignName',
            CREATIVE_ID: 'creativeId',
            REVENUE_TYPE: 'revType',
            REVENUE: 'bid',
            SLOT_NO: 'spotno',
        },
        REVENUE_TYPE: {
            CPC: 'cpc',
            CPM: 'cpm',
        },
        EVENT_BODY_KEYS: {
            OFFERPATH_ID: 'opid',
            AD_ID: 'advid',
            ADVERTISER_NAME: 'advnm',
            CAMPAIGN_ID: 'cmpid',
            CAMPAIGN_NAME: 'cmpnm',
            CREATIVE_ID: 'crid',
            REVENUE_TYPE: 'rv_type',
            REVENUE: 'rvn',
            EFFECTIVE_REVENUE: 'ervn',
        },
        EXTRAS: {
            SURVEY_ID: 'surveyId',
            SURVEY_PATH_ID: 'surveyPathId',
            FLATIRON_MEDIA_PREPING: 'flatiron_preping',
            ARROYO_MEDIA_PREPING: 'arroyo_media_preping',
            IST_MEDIA_SAMPLES_PREPING: 'ist_media_samples_preping',
            IST_MEDIA_AMERICAN_PREPING: 'ist_media_american_preping',
            IST_MEDIA_SURVEY_PREPING: 'ist_media_survey_preping',
            INB_ACCIDENT_PREPING: 'inb_accident_preping',
            INB_LAWSUITE_PREPING: 'inb_lawsuite_preping',
            INB_DEBT_PREPING: 'inb_debt_preping',
            DEVICE_ID: 'deviceId',
            LANDER_ID: 'landerId',
            USER_IP: 'userIP',
            UTM_SOURCE: 'p',
            UTM_TERM: 'kw',
            PENTIUS_PREPING: 'pentius_preping',
            REWARD_ADVISOR_PREPING: 'reward_advisor_preping',
            EMAIL_VERIFICATION: 'evs',
            GIFT_CARD_ID: 'giftCardId',
            UUID: 'uuid',
            PHONE_VERIFICATION: 'pvs',
        },
    },
    SURVEY_CACHE: {
        PAGE_NUM_CACHE_KEY: '__page_num',
    },
    PHONE_VERIFICATION: {
        VERIFIED: 'verified',
        FAILED: 'failed',
    },
    ANALYTICS: {
        CATEGORY: {
            USER: 'user',
            SURVEY: 'survey',
            ADS: 'ads',
            GRATIFICATION: 'gratification',
            MISC: 'miscellaneous',
        },
    },
    JOBS_SEARCH: {
        DEFAULT_JOB_SEARCH_TERM: 'iOS developer',
        DEFAULT_JOB_RADIUS: '100',
        DEFAULT_JOB_PAGE_NUMBER: 1,
        DEFAULT_JOB_RESULTS_PER_PAGE: 10,
        SEARCH_BTN_TEXT: 'SEARCH',
        // DEFAULT_JOB_LOCATION: "Santa Monica"
    },
    UTM_DEFAULT: {
        SOURCE: 'direct',
        MISC: 'none',
    },
    APP_COMPONENT_NAME: {
        LANDING: 'LandingApp',
        SURVEY: 'SurveyApp',
        ADS: 'AdsApp',
        GRATIFICATION: 'GratificationApp',
    },
    SURVEY: {
        SURVEY_WRAPPER_ID: 'survey-wrapper',
    },
    NOTIFICATIONS: {
        /*
         * NOTE: This can be different for different environments as well
         * TODO: Add multiple environments
         */
        VAPID_PUBLIC_KEY:
            'BBQGtXxzkjIQhmmfYIyYafjGIiavIc_VEvUCv1mmvGNkcGIDiVv0VwpZOfwo7u8uSAiV_bTqLNChDjZTnb1XYZs',
        PUSH_ACCESS: 'pa',
    },
    ANTI_SPAM_TEMPLATE_ID: {
        '/landing': {
            lander: 28,
            registration: 36,
        },
        '/survey': 32,
        '/offers': 33,
        '/listings': 34,
    },
    CREATIVE_URL_PARAMS: {
        PRODUCT: '%%max-product%%',
        ZIP_CODE: '%%ZIP_CODE%%',
        SID: '%%SID%%',
        KEYWORD: '%%KEYWORD%%',
        JOB_TYPE: '%%JOB_TYPE%%',
        UTM_CONTENT: '%%UTM_CONTENT%%',
        FIRST_NAME: '%%FIRST_NAME%%',
        LAST_NAME: '%%LAST_NAME%%',
        PHONE: '%%PHONE%%',
        PHONE_CODE: '%%PHONE_CODE%%',
        PHONE_PREFIX: '%%PHONE_PREFIX%%',
        PHONE_SUFFIX: '%%PHONE_SUFFIX%%',
        EMAIL: '%%EMAIL%%',
        GEO_IP: '%%GEO_IP%%',
        GEO_STATE: '%%GEO_STATE%%',
        GEO_CITY: '%%GEO_CITY%%',
        GENDER: '%%GENDER%%',
        DOB: '%%DOB%%',
        DOB_MONTH: '%%DOB_MONTH%%',
        DOB_DAY: '%%DOB_DAY%%',
        DOB_YEAR: '%%DOB_YEAR%%',
        ADDRESS: '%%ADDRESS%%',
        ADDRESS2: '%%ADDRESS2%%',
        MD5EMAIL: '%%MD5EMAIL%%',
        UTM_CAMPAIGN: '%%UTM_CAMPAIGN%%',
        UTM_SOURCE: '%%UTM_SOURCE%%',
        THEME_COLOR: '%%THEME_COLOR%%',
        THEME_GRADIENT: '%%THEME_GRADIENT%%',
        THEME_TEXT_COLOR: '%%THEME_TEXT_COLOR%%',
        THEME_BORDER: '%%THEME_BORDER%%',
        THEME_BORDER_RADIUS: '%%THEME_BORDER_RADIUS%%',
        THEME_FONT_SIZE: '%%THEME_FONT_SIZE%%',
        THEME_FONT_WEIGHT: '%%THEME_FONT_WEIGHT%%',
        THEME_HEIGHT: '%%THEME_HEIGHT%%',
        THEME_WIDTH: '%%THEME_WIDTH%%',
        THEME_PRIMARY_COLOR: `%%THEME_PRIMARY_COLOR%%`,
        THEME_PRIMARY_CONTRAST_TEXT_COLOR: `%%THEME_PRIMARY_CONTRAST_TEXT_COLOR%%`,
        THEME_SECONDARY_COLOR: `%%THEME_SECONDARY_COLOR%%`,
        THEME_SECONDARY_CONTRAST_TEXT_COLOR: `%%THEME_SECONDARY_CONTRAST_TEXT_COLOR%%`,
        THEME_DEFAULT_BACKGROUND_COLOR: `%%THEME_DEFAULT_BACKGROUND_COLOR%%`,
        SUBID: '%%SUBID%%',
        SUB_SOURCE_ID: '%%SUB_SOURCE_ID%%',
        ENCODED_SOURCE: '%%ENCODED_SOURCE%%',
        USER_IP: '%%USER_IP%%',
        UTM_MEDIUM: '%%UTM_MEDIUM%%',
        CLID: '%%CLID%%',
        UTM_CAMPAIGN_BASE64: '%%UTM_CAMPAIGN_BASE64%%',
        IS_MOBILE: '%%IS_MOBILE%%',
        AGE: '%%AGE%%',
        V2_ENCODED_SOURCE: '%%V2_Encoded_Source%%',
    },
    REX_DIRECT_QUESTIONS: {
        RexDirectBRSurvey: {
            logo: 'rex_direct_br_survey.png',
            description:
                'Make Money with Paid Surveys. Rated <b> Excellent </b> on Trustpilot with <b> 40k+ reviews! </b> Verify your email to receive your $1 Welcome Bonus!',
            privacyLink: 'https://surveys.gobranded.com/page/branded-surveys-privacy-policy/',
            category: 'rex_direct_br_survey',
        },
        RexDirectDCDentist: {
            logo: 'rex_direct_dc_dentist.png',
            description:
                'Get access to affordable top-rated dentist in your area. We will help you find the perfect specialist and book your appointment for FREE!',
            privacyLink: false,
            category: 'rex_direct_dc_dentist',
        },
        RexDirectLSEnergy: {
            logo: 'rex_direct_ls_energy.jpeg',
            description: 'Just speak to one of our specialists right now to find out how.',
            privacyLink: 'http://www.rextrack.com/privacy_policy_v1.html',
            category: 'rex_direct_ls_energy',
        },
        RexDirectUSAStudentDebtRelief: {
            logo: 'rex_direct_usa_student_debt_relief.png',
            description: false,
            privacyLink: false,
            category: 'rex_direct_usa_student_debt_relief',
        },
        RexDirectFederalAid: {
            logo: 'rex_direct_federal_aid.png',
            description:
                'Take this 30 Second Email Survey to see if you are eligible for Edu Grants & More that don’t have to be paid back. Earn More with this Advantage! Choose YES & Check Your Inbox for Subject: Response Needed. Open, Click, Receive!',
            privacyLink: 'http://www.rextrack.com/privacy_policy_v1.html',
            category: 'rex_direct_federal_aid',
        },
        RexDirectWomensDay: {
            logo: 'rex_direct_womens_day.png',
            description:
                'Look no further than the Woman’s Day newsletter! Get all the advice you need to navigate short-term goals like making dinner tonight and long-term goals like living a healthier, positive life. Click YES to sign up!',
            privacyLink: 'https://www.hearst.com/-/us-magazines-privacy-notice',
            termLink: 'https://www.hearst.com/-/us-magazines-terms-of-use',
            category: 'rex_direct_womens_day',
        },
    },
    ADVERTISERS: {
        UPWARD: 'upward',
        TALROO: 'talroo',
        ZIPRECRUITER: 'zip_recruiter',
        APPCAST: 'appcast',
        LOCALSTAFFING: 'Local Staffing',
        DATA: {
            UPWARD: {
                NAME: 'Upward',
                ID: '2220',
            },
            TALROO: {
                NAME: 'Talroo',
                ID: '2250',
            },
            ZIPRECRUITER: {
                NAME: 'Ziprecruiter',
                ID: '2440',
            },
            APPCAST: {
                NAME: 'AppCast',
                ID: '2251',
            },
            LOCALSTAFFING: {
                NAME: 'Local Staffing',
                ID: '2252',
            },
            JOBOWL: {
                NAME: 'JobOwl',
                ID: '2429',
            },
        },
    },
    EMAIL_VERIFICATION: {
        VERIFIED: 'verified',
        RETRY: 'retry',
        FAILED: 'failed',
    },
    REDIRECT_MEDIUM: {
        SMS: 'sms',
    },
    AD_UNIT_ID: {
        BRINKS_MONEY_SWEEPS: {
            PROD: 'c115a8f4-965b-4523-9949-42bd69202d1e',
            STAG: '63c84754-7d70-432c-a11c-7634e04b92d6',
        },
        SERP_MARKETPLACE: {
            PROD: '75bdcf62-4076-4a2c-be53-6bff74c93ae7',
            STAG: '51b1ffe2-d211-462a-b5ec-4bf889ea54bf',
        },
        EXIT_INTERSTITIAL: {
            STAG: '1cce3899-6f2a-4064-9952-d94f76766cd9',
            PROD: '142b8c68-eada-4356-b92f-15a5b891e53f',
        },
        TALENT_INC: {
            STAG: '6999fb0e-4ff9-49cc-96ab-43edd9057b4d',
            PROD: 'b187b26b-33e2-484d-857b-fc8b6fc2132a',
        },
        JOBCASE_COREG: {
            STAG: 'b91912b6-277b-4044-88aa-e2a769084007',
            PROD: '9a984af9-eadd-4f48-bdae-fc37a31a9d2a',
        },
        JOBCASE_COREG_SWEEPS: {
            STAG: '2d24497b-0a21-4291-bdec-19f09a8442c9',
            PROD: '38e69a36-724c-4120-9d36-71691746bf7a',
        },
        RDS_ENTRY_VIEW: {
            STAG: '76602cc6-63db-4fc6-9979-112a6c06d8d4',
            PROD: '97d4e90e-9fdb-4451-a41d-b79aa9a4ac88',
        },
        RDS_ENTRY_EMAIL: {
            STAG: '584619c3-f256-48a3-96b4-a324790ba453',
            PROD: '21dd995c-4b47-4d05-8503-fd61853ada56',
        },
        INTEREST_MEDIA_FREE_SAMPLES: {
            STAG: '43939592-efae-4593-b60e-bc65caaa378f',
            PROD: 'a2a9f3a7-88c5-470c-8ab5-8097ccd570a0',
        },
        DVM_DIABETES_SWEEPS: {
            STAG: 'cfd14fb6-c3eb-42fd-8d80-95dbb48130ed',
            PROD: 'f4833187-747e-4c96-be99-6d9fe15d2d1b',
        },
        DVM_ENERGY_SWEEPS: {
            STAG: '12c83e7c-1ed4-4f8e-9aba-cd617f4d49a1',
            PROD: 'b98557ce-5477-43ae-bdb0-d7689eb77a3f',
        },
        DVM_CA_SWEEPS: {
            STAG: 'c0758379-ac55-496e-8715-60eedaeb3d82',
            PROD: 'facba583-7124-41e5-a26c-7ee26b39b35b',
        },
        PUZZ_SWEEPS: {
            STAG: '60aa9b11-8433-4282-8e21-c0c81f2fe93d',
            PROD: 'acb6e931-b408-4e47-ae52-f804c3a7b1ac',
        },
        LIGHT_FIRE_AI: {
            STAG: '12131520-fefd-4556-be68-e6ee8d66172c',
            PROD: 'fbe13dcc-8286-417c-a87f-b591f62f8fd4',
        },
        SUBJECTWELL_CLINIC_TRIAL: {
            STAG: '2ce5e472-7ae4-4966-be51-950affa11b8a',
            PROD: 'b58eaf87-7c61-44f9-ae3e-b5faab6877b6',
        },
        ADQUIRE_RJR_SWEEPS: {
            STAG: 'c5ac7570-95bb-4a82-a13e-c4599b77a465',
            PROD: 'e14ec275-29ef-4617-bcb3-df1cc8469b64',
        },
        EQUOTE_SSDI: {
            STAG: 'b103679d-d944-4d62-bd69-edb3451c8506',
            PROD: '4174aa23-cb0c-4ab1-92e9-ff161b783583',
        },
        GENETIC_CARDIO_SWEEPS: {
            STAG: '8f40e8d5-eaef-44d0-940f-0ba246dfc447',
            PROD: '4c36891b-b7cf-4aa4-ba02-a6ec9fb02e1b',
            PROJECT_ID: 'f37f6e5c-4750-44c4-9aff-f19183d20a9d',
        },
        PGX_SWEEPS: {
            STAG: 'f6561b13-1944-4b41-ac8a-d57090a98b1b',
            PROD: '471bcd77-5a6f-4ca8-b820-5e1ef4780415',
            PROJECT_ID: '52ec7600-eaaf-4eb0-b4b6-0f8a7c45814f',
        },
        ZANTAC_SWEEPS: {
            STAG: '2403de49-eaf9-480d-b404-45db78335450',
            PROD: '2709ddac-affb-462b-8c61-4f57a9bf77a5',
            PROJECT_ID: '6f0301ba-7607-4e55-b3c1-b7d1332baefa',
        },
        ROUNDUP_SWEEPS: {
            STAG: '75733a1c-4bf5-4323-b691-6817d279a376',
            PROD: 'e3b98419-d76b-4a3e-a599-1de9340e58d3',
            PROJECT_ID: '8f3f0fb9-e4dd-4b0c-84d4-ad4b8eb066a7',
        },
        NEUROLOGICAL_GENETIC: {
            STAG: '25e7ecac-a62e-45af-a55e-90fa8005c407',
            PROD: 'fb52859c-199e-4cb9-95f0-9d7381e79a7b',
            PROJECT_ID: '488f81f8-94dd-4e10-a1b1-afb65eb7ae90',
        },
        INTEREST_MEDIA_AMERICAN_SWEEPS: {
            STAG: 'a14929c1-6ced-41f5-9fcc-9343f4e93b2b',
            PROD: 'ca1d567a-6026-4675-b771-e7e54a95d4b6',
        },
        INTEREST_MEDIA_TOP_SURVEY: {
            STAG: '50cab8c0-2fad-47c9-977c-60e6cf427fa2',
            PROD: '21dd995c-4b47-4d05-8503-fd61853ada56',
        },
        CONVERZATE_HERNIA_MESH: {
            STAG: '071c9d6f-03f4-4ad4-a1fa-8cb2c36bb46a',
            PROD: '96790a01-93f9-456e-b4e3-77c8cfb69a86',
            PROJECT_ID: '95a6bb9e-eaa3-4a39-9b79-a0314e4b0965',
        },
        CONVERZATE_3M_EARPLUG: {
            STAG: '517dbc5f-ca01-4a0b-bcba-cfcfb8b0609b',
            PROD: '5b5bb35c-40fd-4787-9719-4cd570e03e49',
            PROJECT_ID: '86fba0a9-76ba-45aa-848c-7fe198e75a9a',
        },
        CONVERZATE_TOXIC_BABY: {
            STAG: 'd122b6d8-e048-49dd-ae0c-7f81e268d719',
            PROD: '54349310-50a1-4a2d-83ed-b9e4f4e27248',
            PROJECT_ID: 'fa6d25cf-4759-4029-846b-9f5c4b58100e',
        },
        CONVERZATE_PHILIP_CANCER: {
            STAG: 'b7afc86e-b432-4b5f-b691-e24a4cdd6350',
            PROD: 'de4aea1d-b2b4-449b-984b-eb039f127e1a',
            PROJECT_ID: '6a7dcd5f-a071-4b86-bbeb-2dcc9b415fb7',
        },
    },
    CREATIVE_URL_PARAMS_REGEX: /%%ZIP_CODE%%|%%SID%%|%%KEYWORD%%|%%JOB_TYPE%%|%%UTM_CONTENT%%|%%FIRST_NAME%%|%%LAST_NAME%%|%%PHONE%%|%%PHONE_CODE%%|%%PHONE_PREFIX%%|%%PHONE_SUFFIX%%|%%EMAIL%%|%%GEO_IP%%|%%GEO_CITY%%|%%GEO_STATE%%|%%GENDER%%|%%DOB%%|%%ADDRESS%%|%%MD5EMAIL%%|%%UTM_CAMPAIGN%%|%%UTM_SOURCE%%|%%SUBID%%|%%ENCODED_SOURCE%%|%%THEME_COLOR%%|%%THEME_GRADIENT%%|%%THEME_TEXT_COLOR%%|%%THEME_BORDER%%|%%THEME_BORDER_RADIUS%%|%%THEME_FONT_SIZE%%|%%THEME_FONT_WEIGHT%%|%%THEME_HEIGHT%%|%%THEME_WIDTH%%|%%OPID%%|%%ADDRESS2%%|%%CLID%%|%%UTM_MEDIUM%%|%%DOB_MONTH%%|%%DOB_DAY%%|%%DOB_YEAR%%|%%AGE%%|%%SUB_SOURCE_ID%%|%%USER_IP%%|%%max-product%%|%%UTM_CAMPAIGN_BASE64%%|%%IS_MOBILE%%|%%V2_Encoded_Source%%/gi,
    CREATIVE_CATEGORIES: ['appcast_cpa', 'linkout', 'trucking', 'gigs', 'warehouse', 'jf_linkout'],
    TCPA_OPTIN_TEXT_SMS: `I agree to be contacted by phone and/or receive daily recurring SMS text messages (messages and data rates may apply) by MyJobsCorner And Marketing Partners at the phone number provided (including my wireless) , whether or not the number is the federal or state do not call registry. I acknowledge that these calls or SMS messages may be made using an autodialer and may also contain pre-recorded messages. For SMS message campaigns: Text STOP to stop and HELP for help. Msg & data rates may apply. I understand my consent is not required to perform a job search, or to purchase or use any goods or services offered. To receive this information without providing this consent, skip `,
    TCPA_OPTIN_TEXT: `I agree to be contacted by phone and/or receive daily recurring SMS text messages (messages and data rates may apply) by MyJobsCorner `,
    TCPA_EXTRA_PARTNERS: ', Citizens Disability, National Disability, ',
    TCPA_OPTIN_TEXT_CONT: ` at the phone number provided (including my wireless) , whether or not the number is the federal or state do not call registry. I acknowledge that these calls or SMS messages may be made using an autodialer and may also contain pre-recorded messages. For SMS message campaigns: Text STOP to stop and HELP for help. Msg & data rates may apply. I understand my consent is not required to perform a job search, or to purchase or use any goods or services offered. To receive this information without providing this consent, skip `,
    TCPA_PHONE_PART1:
        'I agree to be contacted by phone and/or receive daily SMS text messages (messages and data rates may apply) from MyJobsCorner ',
    TCPA_PHONE_PART2:
        '  at the phone number provided (including my wireless) , whether or not the number is the federal or state do not call registry. I acknowledge that these calls or SMS messages may be generated using an autodialer and may also contain pre-recorded messages. I understand my consent is not required to perform a job search or as a condition of purchasing any goods or services.',
    TCPA_PHONE_STOP:
        'For SMS message campaigns: Text STOP to stop and HELP for help. Msg & data rates may apply. Periodic messages; max. 20/month ',
    TCPA_EMAIL_PART1:
        'By clicking on "Confirm" I agree to the terms of use & privacy policy and to receive email updates for new job listings and career opportunities from ',
    TCPA_EMAIL_PART2: ' and our Marketing Partners.',
    EDU_OPTIN_TEXT: `By clicking “Agree and Continue”, I agree to be contacted by phone or received daily SMS text message (message and data rates may apply) by DegreeUSA, EDU Degree at {userPhoneNumber} (including my wireless) regarding educational opportunities. I acknowledge that these calls or sms messages may be generated using an autodialer and may also contain pre-recorded messages. I understand my consent is not required as a condition of purchasing any goods or services. To receive this information without providing consent, skip`,
    DEFAULT_JOB_TYPE_ICONS: ['amazon', 'walmart', 'fedex', 'costco'],
    JOBS_NEAR_ME: 'Jobs near me',
    JOBS_CATEGORY_QN: 'Which job categories are you interested in?',
    EVERY_JOB_FOR_ME_QN: 'This will help EveryJobForMe send you the best jobs',
    EVERY_JOB_FOR_ME_CHECK: 'Yes, sign me up for daily email job alerts from EveryJobForMe',
    SID_SMS_COMPLIANCE: '54',
    ADQUIRE_DEGREE_H2:
        'Boost your earning potential today by furthering your education. Class Values will help you quickly find the school that has the programs that fit your needs. Sign up today and one of our education partners will contact you soon to discuss all of your options. ',
    ADQUIRE_DEGREE_TCPA:
        'By clicking "Submit", I confirm that the information provided on this form is accurate and complete to the best of my knowledge and I agree to receive email communications, phone calls, and SMS/Text Messages from Class Values and its affiliates at the numbers provided above, including my wireless numbers if provided. Contact methods may include calls or texts made using automated technology or pre-recorded voice messages. I understand that my consent is not a condition of purchasing services from Class Values.',
    AD_UNIT: {
        CUSTOM_AD_INFO: {
            TYPE: {
                JOB: 'job',
            },
        },
    },
    PLACEMENT_TYPE: {
        EXIT_INTERSTITIAL: 'exit_interstitial',
    },
    LABELS: {
        LANDING: {
            ZIP: 'Verify Your Zip Code',
            CTA: 'View Jobs',
        },
    },
    DEFAULT_PAY_RANGE: '$15-$26/Hour',
    MAX_REPLACEMENT_MACROS: {
        creativeTitle: '%%max-ad-title%%',
        displayUrl: '%%max-ad-display-url%%',
        creativeIcon: '%%max-ad-icon%%',
        creativeImage: '%%max-ad-main-image%%',
        creativeDescription1: '%%max-ad-description-1%%',
        creativeDescription2: '%%max-ad-description-2%%',
        onClickTrackingPixels: '%%max-ad-click-url%%',
        trackingPixels: '%%max-ad-impression-url%%',
        bid: '%%max-ad-bid-value%%',
        revType: '%%max-ad-bid-type%%',
        // under customAdInfo
        link: '%%max-ad-custom-link%%', // (currently being use for SERP ads)
        target: '%%max-ad-custom-target%%', // (currently being used for Neuvoo SERP ad)
        type: '%%max-ad-custom-ad-type%%', // (currently being used for Neuvoo SERP ad)
        adCTA1: '%%max-ad-cta-1%%',
        adCTA2: '%%max-ad-cta-2%%',
        image: '%%max-ad-image%%',
        creativeType: '%%max-ad-creative-type%%',
        advId: '%%max-ad-adv-id%%',
        campaignId: '%%max-ad-cmp-id%%',
        adCategory: '%%max-ad-category%%',
        cpcFloor: '%%max-ad-cpc-floor%%',
        macro1: '%%max-macro-1%%',
        macro2: '%%max-macro-2%%',
        macro3: '%%max-macro-3%%',
        product: '%%max-product%%',
        UTM_CAMPAIGN_BASE64: '%%UTM_CAMPAIGN_BASE64%%',
    },
    JOBCASE_AUTH_TOKEN: 'mnMGUY93B0ZCqppkZJM3dQ',
    MAX_MACROS_REGEX: /%%max-ad-title%%|%%max-ad-display-url%%|%%max-ad-icon%%|%%max-ad-description-1%%|%%max-ad-description-2%%|%%max-ad-click-url%%|%%max-ad-impression-url%%|%%max-ad-bid-value%%|%%max-ad-bid-type%%|%%max-ad-custom-link%%|%%max-ad-custom-target%%|%%max-ad-custom-ad-type%%|%%max-ad-cta-1%%|%%max-ad-cta-2%%|%%max-ad-image%%|%%max-ad-main-image%%|%%max-ad-creative-type%%|%%max-macro-1%%|%%max-macro-2%%|%%max-macro-3%%|%%max-product%%|%%UTM_CAMPAIGN_BASE64%%/gi,
    CR_MAX_UNIFIED_REGEX: /%%ZIP_CODE%%|%%SID%%|%%KEYWORD%%|%%JOB_TYPE%%|%%UTM_CONTENT%%|%%FIRST_NAME%%|%%LAST_NAME%%|%%PHONE%%|%%PHONE_CODE%%|%%PHONE_PREFIX%%|%%PHONE_SUFFIX%%|%%EMAIL%%|%%GEO_IP%%|%%GEO_CITY%%|%%GEO_STATE%%|%%GENDER%%|%%DOB%%|%%ADDRESS%%|%%MD5EMAIL%%|%%UTM_CAMPAIGN%%|%%UTM_SOURCE%%|%%SUBID%%|%%ENCODED_SOURCE%%|%%THEME_COLOR%%|%%THEME_GRADIENT%%|%%THEME_TEXT_COLOR%%|%%THEME_BORDER%%|%%THEME_BORDER_RADIUS%%|%%THEME_FONT_SIZE%%|%%THEME_FONT_WEIGHT%%|%%THEME_HEIGHT%%|%%THEME_WIDTH%%|%%OPID%%|%%ADDRESS2%%|%%CLID%%|%%UTM_MEDIUM%%|%%DOB_MONTH%%|%%DOB_DAY%%|%%DOB_YEAR%%|%%AGE%%|%%SUB_SOURCE_ID%%|%%USER_IP%%|%%max-ad-title%%|%%max-ad-display-url%%|%%max-ad-icon%%|%%max-ad-description-1%%|%%max-ad-description-2%%|%%max-ad-click-url%%|%%max-ad-impression-url%%|%%max-ad-bid-value%%|%%max-ad-bid-type%%|%%max-ad-custom-link%%|%%max-ad-custom-target%%|%%max-ad-custom-ad-type%%|%%max-ad-cta-1%%|%%max-ad-cta-2%%|%%max-ad-image%%|%%max-ad-main-image%%|%%max-ad-creative-type%%|%%max-macro-1%%|%%max-macro-2%%|%%max-macro-3%%|%%max-product%%|%%UTM_CAMPAIGN_BASE64%%|%%IS_MOBILE%%|%%V2_Encoded_Source%%/gi,
    PREPPING_PROXY_URL: 'https://api.cnxdserv.com/a/api/v2/proxy',
    API_PROXY_URL: 'https://api.cnxdserv.com/a/api/v2/proxy',
    PREPING_URLS: {
        PUZZ: 'https://puzz.biglist.com/api/1.1/lists/news/subscriptions',
        DVM: 'https://dvm.api.twyne.io/preping/check',
        LIGHT_FIRE_AI:
            'https://lms.lightfirepartners.com/ping/48cf9c1d-0661-4757-a3ab-4f3408e9390a',
        SUBJECTWELL_CLINIC_TRIAL: 'https://api.subjectwell.com/v1/pre_ping',
        SSDI: 'https://tvsa.leadportal.com/new_api/api.php',
        CONVERZATE: 'https://api.converzate.com/v1/ping',
        FLATIRON:
            'https://fmmanagedadvertiserprepings.azurewebsites.net/api/rungoodrx?code=nqHsvQawDjGgIs/5LgoCcEGGGtOJ2V7jPiva0X8FUdZC908kfdj0Dg==',
        REWARD_ADVISOR:
            'https://post.digitalmediasolutions.com/b25d9995d01a24b451f44e21bfe2a5f5/post?email_md5=',
        INTEREST_MEDIA_FREE_SAMPLES: 'https://leads.freesamplesprousa.com/api/externallead/preping',
        INTEREST_MEDIA_AMERICAN_SWEEPS:
            'https://leads.theamericansweepstakes.com/api/externallead/preping',
        INTEREST_MEDIA_TOP_SURVEY: 'https://leads.topsurveyspot.com/api/externallead/preping',
        ARROYO_MEDIA: 'https://sweepsclub.net/api/ping/check?pid=2137&email=',
        PENTIUS: 'https://portal.systemadmin.com/preping_check.asp',
        INBOUND: 'https://api.autoaccidentteam.com/email-check?email=',
    },
    INBOUND_2: '&pub_id=157',
    PREPING_API_KEY: {
        SUBJECTWELL_CLINIC_TRIAL: 'f79b6e31fd3eb3004d28272d190b2f87',
        CONVERZATE: 'HjXTunZKx02J9WaCCWRSH3ORmkmBLdFp8j7AS4qQ',
    },
    SSDI_KEY: '30b487787e754e3f9841a0e6d962510931bfd1334b8dd9f687a862084e4a9053',
    ADQUIRE_CAMPAING_UUID: '6e5ba86f-e8db-7ebc-7295-154c44f81320',
    ADQUIRE_PLACEMENT_UUID: '39c8c0ef-e228-d3f7-7bc6-3755c154374a',
    ADQUIRE_PREPING_URL: 'https://api.adquire.com/adquire-edge/preping/',
    SWEEPS_OFFER_WALL_IDS: {
        BACK_TO_OFFER_WALL: {
            STAG: '14',
            PROD: '10',
        },
        DIRECT_OFFER_WALL: {
            STAG: '17',
            PROD: '11',
        },
        STAND_ALONE_OFFER_WALL: {
            STAG: '26',
            PROD: '11',
        },
    },
    ADQUIRE_MARIA_HEADER: 'Discover the Key to your Future for 2022 with Maria Medium',
    OPT_IN_TIME_STAMP_FORMAT: 'YYYY-MM-DD HH:mm:ss Z',
    CLERGY_QUESTION_TITLE:
        'Have you or your loved one ever been sexually abused by a member of clergy? ',
    CUSTOM_QU_SUBTITLE: 'Check all that apply & click DONE',
    EQUOTO_FINAL_EXP_BODY:
        'Help your family be prepared for the burden of final expenses. Select this offer for Easy, Affordable Coverage with No Medical Exam.',
    EQUOTO_FINAL_EXP_TCPA: `By submitting this form, I agree that I am 18+ years of age and agree to this website's <a href='https://equoto.com/privacy-policy/' target='_blank' style='color: inherit;'>Privacy Policy</a> and <a href='https://equoto.com/terms-conditions/' target='_blank' style='color: inherit;'>Terms & Conditions</a> and I also hereby give my express written consent to receive marketing communications regarding a Final Expense quote via autodialed calls and/or SMS/MMS, and/or pre-recorded or artificial voice calls eQuoto and/or one or more of its marketing <a href='https://equoto.com/partners/' target='_blank' style='color: inherit;'>partners</a>, at the phone number and/or e-mail address provided to us, including wireless numbers, if applicable, even if I have previously registered the provided number on the Do Not Call Registry. Carrier SMS/MMS and data messaging rates may apply. This consent is not required as a condition to purchase services or products.`,
};
