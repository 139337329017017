import { ConfigProvider } from 'providers';
import Utils from 'common/utils';
import { APP_SCOPES } from 'common/scopeUtils';
import { parseValueToArray, getCurrentRoute } from '../common';

export const getFeatureValues = (featureKey, scope = APP_SCOPES.GLOBAL) => {
    if (ConfigProvider && ConfigProvider.Features && ConfigProvider.Features.getFeature) {
        const feature = ConfigProvider.Features.getFeature(featureKey, scope);

        if (feature && !Utils.isEmptyStr(feature.value)) {
            return parseValueToArray(feature.value).map(val => decodeURIComponent(val));
        }
    }

    return [];
};

export const getFeatureMapValue = (featureKey, scope = APP_SCOPES.GLOBAL) => {
    if (ConfigProvider && ConfigProvider.Features && ConfigProvider.Features.getFeature) {
        const feature = ConfigProvider.Features.getFeature(featureKey, scope);

        if (feature && !Utils.isEmptyStr(feature.value)) {
            return feature.value;
        }
    }

    return '';
};

export const getFeatureValuesByCurrentScope = (
    featureKey,
    scope = getCurrentRoute(),
    fbScope = APP_SCOPES.GLOBAL,
) => {
    const scopeValue = getFeatureValues(featureKey, scope);
    const fbValue = fbScope !== null ? getFeatureValues(featureKey, fbScope) : [];

    if (scopeValue && scopeValue.length) {
        return scopeValue;
    } else return fbValue;
};

export const getFeatureMapValuesByCurrentScope = (
    featureKey,
    scope = getCurrentRoute(),
    fbScope = APP_SCOPES.GLOBAL,
) => {
    return (
        getFeatureMapValue(featureKey, scope) ||
        (fbScope && getFeatureMapValue(featureKey, fbScope))
    );
};

export const getFeatureValueJson = (featureKey, scope = APP_SCOPES.GLOBAL) =>
    getFeatureValues(featureKey, scope).map(Utils.jsonSafeParse);

export const getFeatureValueJsonByCurrentScope = (featureKey, scope, fbScope) =>
    getFeatureValuesByCurrentScope(featureKey, scope, fbScope).map(Utils.jsonSafeParse);
