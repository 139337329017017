import UrlManager from 'common/urlManager';
import { fetchRetry } from 'providers/utils';
import Constants from 'common/constants';
import Utils from 'common/utils';

export default class GiftCardUrlService {
    static getValidationForSurl(surlToken) {
        return new Promise((resolve, reject) => {
            const url = UrlManager.getOfferWallEntryValidatorUrl(surlToken);

            if (Utils.isLocalEnv()) {
                fetch(Constants.JOBCASE_PREPPING_PROXY_URL, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                    },
                    body: JSON.stringify({
                        url: url,
                        options: {
                            method: 'GET',
                            headers: {
                                'content-type': 'application/x-www-form-urlencoded',
                            },
                        },
                    }),
                })
                    .then(res => res.json())
                    .then(res => {
                        resolve(res);
                    })
                    .catch(sessionErr => {
                        console.log('ERR: REWARD_STATUS: error - ', sessionErr);
                        reject(sessionErr);
                    });
            } else {
                fetchRetry(url, {
                    method: 'GET',
                    mode: 'no-cors',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(sessionErr => {
                        console.log('ERR: REWARD_STATUS: error - ', sessionErr);
                        reject(sessionErr);
                    });
            }
        });
    }
}
