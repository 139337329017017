import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    container: {
        height: 'calc(100vh -60px)',
        display: 'flex',
        margin: '0 auto',
        alignItems: props => (props.alignTop ? 'flex-start' : 'center'),
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            minHeight: '500px',
            borderRadius: 10,
            boxShadow: '0px 4px 20px #ededed',
            backgroundColor: theme.palette.common.white,
        },
    },
}));
