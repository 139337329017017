/* eslint-disable prettier/prettier */
import Constants from 'common/constants';
import Utils from 'common/utils';
import { IdStore } from 'dataStore/index';
import { ConfigProvider, FeatureMap } from 'providers';
import { parseValueToArray, getCurrentRoute } from '../common';

const matchFeature = (feature, value) => {
    let match = false;

    if (feature && !Utils.isEmptyStr(feature.value)) {
        parseValueToArray(feature.value).some(val => {
            if (decodeURIComponent(val) === value) {
                match = true;

                return true;
            }

            return false;
        });
    }

    return match;
};

const matchFeatureByKey = (featureKey, value) => {
    const feature = ConfigProvider.Features.getFeature(featureKey);

    return matchFeature(feature, value);
};

const getFeatureValues = featureKey => {
    if (ConfigProvider && ConfigProvider.Features && ConfigProvider.Features.getFeature) {
        const feature = ConfigProvider.Features.getFeature(featureKey);

        if (feature && !Utils.isEmptyStr(feature.value)) {
            return parseValueToArray(feature.value).map(val => decodeURIComponent(val));
        }
    }

    return null;
};

const skipToOfferPathSource = () => {
    const skipToOfferPathSources = ConfigProvider.Features.getFeature(
        FeatureMap.SkipToOfferPathSources,
    );
    const fbAwksModel = ConfigProvider.Features.getFeature(FeatureMap.FBSkipToOffers);

    const source = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_SOURCE);
    const src = !Utils.isNull(source) && !Utils.isEmptyStr(source) ? source.toLowerCase() : '';

    return matchFeature(skipToOfferPathSources, src) || matchFeature(fbAwksModel, '1');
};

const skipToOfferPathChannel = () => {
    const skipToOfferPathChannel = ConfigProvider.Features.getFeature(
        FeatureMap.SkipToOfferPathChannel,
    );

    const channel = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_MEDIUM) || '';

    return matchFeature(skipToOfferPathChannel, channel);
};

const googleDisplaySkipToOffer = () => {
    const googleDisplaySkipToOfferPath = ConfigProvider.Features.getFeature(
        FeatureMap.GoogleDisplaySkipToOfferPath,
    );

    if (
        googleDisplaySkipToOfferPath &&
        googleDisplaySkipToOfferPath.value &&
        googleDisplaySkipToOfferPath.value === 'skip-to-offers'
    ) {
        return true;
    }

    return false;
};

const fbTrafficSkipToOffer = () =>
    skipToOfferPathSource() || skipToOfferPathChannel() || googleDisplaySkipToOffer();

const oldUsersSkipToOffer = () => {
    if (skipToOfferPathChannel()) {
        return false;
    }

    const oldUserSkipToOfferPath = ConfigProvider.Features.getFeature(
        FeatureMap.OldUserSkipToOfferPath,
    );

    if (
        oldUserSkipToOfferPath &&
        oldUserSkipToOfferPath.value &&
        oldUserSkipToOfferPath.value === 'old-user-skip-to-offers'
    ) {
        return true;
    }

    return false;
};

const skipToOffer = () => fbTrafficSkipToOffer() || oldUsersSkipToOffer();

const oldUserJobcaseOffer = () => {
    if (fbTrafficSkipToOffer()) {
        return false;
    }

    const oldUserJobcaseOfferFeature = ConfigProvider.Features.getFeature(
        FeatureMap.OldUserJobcaseOffer,
    );

    const channel = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_MEDIUM) || '';

    if (channel === 'dxml' || channel === 'dxml_static') {
        return false;
    }

    return matchFeature(oldUserJobcaseOfferFeature, 'repeat-user-jobcase-offer');
};

const enableNotifyZipToOffers = () => {
    const enabledSources = ConfigProvider.Features.getFeature(FeatureMap.NotifyZipToOffers);
    const source = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_SOURCE);
    const src = !Utils.isNull(source) && !Utils.isEmptyStr(source) ? source.toLowerCase() : '';

    return matchFeature(enabledSources, src);
};

const enableInterstitialOfferPath = () => {
    const interstitialOfferPathFeature = ConfigProvider.Features.getFeature(
        FeatureMap.InterstitialBetweenOfferPath,
    );

    return (
        matchFeature(interstitialOfferPathFeature, 'Interstitial-v1') ||
        matchFeature(interstitialOfferPathFeature, 'Interstitial-v2') ||
        matchFeature(interstitialOfferPathFeature, 'Interstitial-v3')
    );
};

const enableBack2ListingsMidPathOffer = () => {
    const enableListingsToMidPathOffer = ConfigProvider.Features.getFeature(
        FeatureMap.Back2ListingsMidPathRedirection,
    );

    return matchFeature(enableListingsToMidPathOffer, 'redirect-to-offers');
};

const fbGoogleEmailInterstitial = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.FbGoogleEmailInterstitial);

    if (matchFeature(feature, 'fb-email-interstitial')) {
        return true;
    }

    if (googleDisplaySkipToOffer()) {
        return matchFeature(feature, 'google-email-interstitial');
    }

    return false;
};

const multiBrandLogo = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.MultiBrandKWLogo);

    return (
        matchFeature(feature, 'multi-brand-logo') ||
        matchFeature(feature, 'multi-brand-logo-full-time-jobs')
    );
};

const listingsInBgWithZipInEmail = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.ListingsInBg);

    return matchFeature(feature, 'listings_in_bg_with_zipInEmail');
};

const noSkipToOffersListingsInBg = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.NoSkipToOffersTest);

    return matchFeature(feature, 'no-skip-to-offers');
};

const listingsInBgWithZip = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.ListingsInBg);

    return matchFeature(feature, 'listings_in_bg_with_zip') || noSkipToOffersListingsInBg();
};

const listingsInBgHybridFlow = () => {
    const scope = getCurrentRoute();
    const surveyFeature = ConfigProvider.Features.getFeature(
        FeatureMap.ListingsInBgHybridSurveyFlow,
        scope,
    );
    const offersFeature = ConfigProvider.Features.getFeature(
        FeatureMap.ListingsInBgHybridOffersFlow,
        scope,
    );

    return (
        matchFeature(surveyFeature, 'listings_in_bg_hybrid_flow') ||
        matchFeature(offersFeature, 'listings_in_bg_hybrid_flow')
    );
};

const listingsInBgMidpathOffers = () => {
    const scope = getCurrentRoute();
    const feature = ConfigProvider.Features.getFeature(FeatureMap.ListingsInBgMidpathOffers, scope);

    return matchFeature(feature, 'listings_in_bg_midpath_offers');
};

const listingsInBgLandingFlow = () => listingsInBgWithZipInEmail() || listingsInBgWithZip();

const showListingsBgXMLTestV2 = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.ListingsBgXmlStaticImageTest);

    return matchFeature(feature, 'true');
};

const xmlListingsInBgWithoutZip = () => {
    return matchFeatureByKey(FeatureMap.XMLListingBgSkipWithoutZip, '1');
};

const listingsInBgFlow = () =>
    listingsInBgLandingFlow() ||
    listingsInBgHybridFlow() ||
    listingsInBgMidpathOffers() ||
    showListingsBgXMLTestV2() ||
    xmlListingsInBgWithoutZip();

const listingsInBgPrelanderKw = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.ListingsInBgPrelanderKw);

    return matchFeature(feature, 'listings_in_bg_prelander_kw');
};

const rgKeywordTest = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.RgKeyword);

    return (
        matchFeature(feature, 'rg_kw_flow') ||
        (matchFeature(feature, 'rg_kw_google_flow') && googleDisplaySkipToOffer())
    );
};

const googleProgressBarTest = () => {
    if (googleDisplaySkipToOffer() || rgKeywordTest()) {
        return false;
    }

    const feature = ConfigProvider.Features.getFeature(FeatureMap.GoogleProgressBarTest);

    if (feature && feature.value && feature.value === 'googleProgressBar') {
        return true;
    }

    return false;
};

const listingsInBgWithZipUpwardOpt = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.ListingsInBgWithZipUpwardOpt);

    return matchFeature(feature, 'reg2-optin');
};

const reg2Optimize = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.Reg2Optimize);

    return matchFeature(feature, 'Reg2-Opt');
};

const surveyQueVaraition = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.SurveyQueVaraition);

    return matchFeature(feature, 'SurveyQueAge');
};

const jobcaseCoregUI = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.JobcaseCoregUI);

    return matchFeature(feature, 'jobcasecoreg-variation');
};

const repeatUser = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.RepeatUser);

    return matchFeature(feature, 'repeatuser-variation');
};

const aibemLander = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.AIBEMLander);

    return matchFeature(feature, 'aibem-lander');
};

const xmlPartTimeBg = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.XMLPartTimeBG);

    return matchFeature(feature, 'white-bg');
};

const kwSelectorRGLandingPageTest = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.KWSelectorRGLandingPageTest);

    return matchFeature(feature, 'kw-selector-rg-landing');
};

const prePopKwSelectorLanderTest = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.PrePopKWSelectorLanderTest);

    return matchFeature(feature, 'pre-pop-kw-selector');
};

const googleKWSelectorLandingPage = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.GoogleKWSelectorLandingPage);

    return matchFeature(feature, 'google_search');
};

const googleKWSelectorLanderMapping = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.GoogleKWSelectorLanderMapping);

    return feature && !Utils.isEmptyStr(feature.value) && decodeURIComponent(feature.value);
};

const upwardEmailAndRegPageOptimization = () => {
    const feature = ConfigProvider.Features.getFeature(
        FeatureMap.UpwardEmailAndRegPageOptimization,
    );

    return matchFeature(feature, 'page_optimization');
};

const allInboxSMSLander = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.AllInboxSMSLander);

    return matchFeature(feature, '1');
};

const isRGDropdownLander = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.RGDropdownLander);

    return matchFeature(feature, 'dropdown_view');
};
const eduQuestionVar1 = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.EduQuestionVar);

    return matchFeature(feature, 'edu-que-var1');
};

const eduQuestionVar2 = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.EduQuestionVar);

    return matchFeature(feature, 'edu-que-var2');
};

const isAWKSMultiBrandLander = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.AWKSMultiBrandLander);

    return matchFeature(feature, 'multi_brand_logo');
};

const getAWKSMultiBrandLanderLogos = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.AWKSMultiBrandLanderLogos);

    return feature && !Utils.isEmptyStr(feature.value) && decodeURIComponent(feature.value);
};

const isSeniorJobsFacebookTest = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.SeniorJobsFacebook);

    return matchFeature(feature, 'senior_jobs_facebook');
};

const multiBrandLander = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.MultiBrandLander);

    return matchFeature(feature, '1');
};

const upwardEmailOptinTest = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.UpwardEmailOptInTest);

    return matchFeature(feature, 'no-email-optin-upward');
};

const isListingsInBGWithWhiteBGTest = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.ListingsInBGWithWhiteBGTest);

    return matchFeature(feature, 'white_bg');
};

const isSnapchatMultiBrandLander = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.SnapchatMultiBrand);

    return matchFeature(feature, 'multi_brand_logo');
};

const getSnapchatMultiBrandLogos = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.SnapchatMultiBrandLogoList);

    return feature && !Utils.isEmptyStr(feature.value) && decodeURIComponent(feature.value);
};

const getPushOptInPlacementValue = () => {
    const feature = ConfigProvider.Features.getFeature(FeatureMap.PushOptInPlacement);

    return feature && !Utils.isEmptyStr(feature.value) && decodeURIComponent(feature.value);
};
const rgStaticKwImages = () =>
    matchFeature(ConfigProvider.Features.getFeature(FeatureMap.RgStaticKwImage), '1');

const newLanderHeaderTest = () => {
    const feature = ConfigProvider.Features.getFeature(
        FeatureMap.LANDER_HEADER_ON_EMAIL,
        'landing',
    );
    const giftCardIdInUrl = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.GIFTCARD_ID);

    return matchFeature(feature, '1') && !Utils.isEmptyStr(giftCardIdInUrl);
};

const getCustomQuestionsList = () => {
    const customQuestions = [];
    const customQuestionFeature = ConfigProvider.Features.getFeature(
        FeatureMap.customQuestionsListRDS,
    );

    const customQuestionsList =
        customQuestionFeature && customQuestionFeature.value
            ? customQuestionFeature.value.split(',')
            : [];
    customQuestions.push(...customQuestionsList);

    return customQuestions;
};

export const subjectwellWhiteBackgroundTest = () => true;

const autoInsuranceVariant1 = () => matchFeatureByKey(FeatureMap.AutoInsuranceTest, '1');
const autoInsuranceVariant2 = () => matchFeatureByKey(FeatureMap.AutoInsuranceTest, '2');
const autoInsuranceVariantTest = () => autoInsuranceVariant1() || autoInsuranceVariant2();
const JobCategoryQuestionTest = () =>
    matchFeatureByKey(FeatureMap.SurveyJobCategoryQuestionTest, 'v1');
const JobCategoryQuestionTestV2 = () =>
    matchFeatureByKey(FeatureMap.SurveyJobCategoryQuestionTest, 'v2');

export const exxonCreativeVariant = () => {
    const exxonVariant = getFeatureValues(FeatureMap.ExxonCreative);

    return (exxonVariant && exxonVariant.length > 0 && exxonVariant[0]) || '';
};

export const getCampaignDetailMapping = () =>
    Utils.jsonSafeParse(getFeatureValues(FeatureMap.CampaignDetailMapping));
export const JobCategoryQuestionTestV2BTFNo = () =>
    matchFeatureByKey(FeatureMap.SurveyJobCategoryQuestionTest, 'v2-no-btf');

const pushOptInPlacementTest = () =>
    matchFeatureByKey(FeatureMap.PushOptInPlacementTest, 'push-optin-placement');

export const repeatUserAPIFlowV1 = () =>
    matchFeatureByKey(FeatureMap.APIRepeatUserFlow, 'variant-v1');
export const repeatUserAPIFlowV2 = () =>
    matchFeatureByKey(FeatureMap.APIRepeatUserFlow, 'variant-v2');
export const repeatUserAPIFlowV3 = () =>
    matchFeatureByKey(FeatureMap.APIRepeatUserFlow, 'variant-v3');
export const repeatUserAPIFlow = () =>
    repeatUserAPIFlowV1() || repeatUserAPIFlowV2() || repeatUserAPIFlowV3();

export const showSlotPreLander = () => matchFeatureByKey(FeatureMap.SweepsSlotLander, '1');

export const fbClidReplacement = () => matchFeatureByKey(FeatureMap.FbclidReplacement, '1');

const midPathOffersBackgroundVariant1 = () =>
    matchFeatureByKey(FeatureMap.RDSMidPathBackgroundTest, 'variant-1');

const midPathOffersBackgroundVariant2 = () =>
    matchFeatureByKey(FeatureMap.RDSMidPathBackgroundTest, 'variant-2');

const midPathOffersBackgroundVariant3 = () =>
    matchFeatureByKey(FeatureMap.RDSMidPathBackgroundTest, 'variant-3');

const midPathOffersBackgroundVariant4 = () =>
    matchFeatureByKey(FeatureMap.RDSMidPathBackgroundTest, 'variant-4');

const midPathOffersBackgroundTest = () =>
    midPathOffersBackgroundVariant1 ||
    midPathOffersBackgroundVariant2() ||
    midPathOffersBackgroundVariant3() ||
    midPathOffersBackgroundVariant4();

const midPathOffersBackgroundTestVariant = () =>
    midPathOffersBackgroundVariant3() || midPathOffersBackgroundVariant4();

export const sweepLanderV2 = () => matchFeatureByKey(FeatureMap.SweepsLander, 'v2');
export const sweepLanderV3 = () => matchFeatureByKey(FeatureMap.SweepsLander, 'v3');

export const Reg2TCPAOptest = () => matchFeatureByKey(FeatureMap.REG2_TCPA_OPTION_TEST, 'variant');
export const isNewFallbackFeature = () => matchFeatureByKey(FeatureMap.NEW_FALLBACK_LOGIC, '1');
export const dfShouldValidateIframeHTML = () =>
    matchFeatureByKey(FeatureMap.DEV_FEATURE_SHOULD_VALIDATE_IFRAME_HTML, '1');
export {
    getFeatureValues,
    rgStaticKwImages,
    multiBrandLander,
    xmlListingsInBgWithoutZip,
    skipToOfferPathSource,
    skipToOfferPathChannel,
    oldUsersSkipToOffer,
    fbTrafficSkipToOffer,
    skipToOffer,
    oldUserJobcaseOffer,
    enableNotifyZipToOffers,
    fbGoogleEmailInterstitial,
    enableInterstitialOfferPath,
    googleDisplaySkipToOffer,
    enableBack2ListingsMidPathOffer,
    multiBrandLogo,
    listingsInBgWithZipInEmail,
    noSkipToOffersListingsInBg,
    listingsInBgWithZip,
    listingsInBgHybridFlow,
    listingsInBgMidpathOffers,
    listingsInBgLandingFlow,
    listingsInBgFlow,
    listingsInBgPrelanderKw,
    googleProgressBarTest,
    listingsInBgWithZipUpwardOpt,
    showListingsBgXMLTestV2,
    aibemLander,
    surveyQueVaraition,
    jobcaseCoregUI,
    rgKeywordTest,
    xmlPartTimeBg,
    googleKWSelectorLandingPage,
    googleKWSelectorLanderMapping,
    repeatUser,
    kwSelectorRGLandingPageTest,
    reg2Optimize,
    prePopKwSelectorLanderTest,
    upwardEmailAndRegPageOptimization,
    allInboxSMSLander,
    isRGDropdownLander,
    eduQuestionVar1,
    eduQuestionVar2,
    isAWKSMultiBrandLander,
    getAWKSMultiBrandLanderLogos,
    isSeniorJobsFacebookTest,
    upwardEmailOptinTest,
    isListingsInBGWithWhiteBGTest,
    isSnapchatMultiBrandLander,
    getSnapchatMultiBrandLogos,
    pushOptInPlacementTest,
    getPushOptInPlacementValue,
    newLanderHeaderTest,
    autoInsuranceVariant1,
    autoInsuranceVariant2,
    autoInsuranceVariantTest,
    JobCategoryQuestionTest,
    JobCategoryQuestionTestV2,
    midPathOffersBackgroundVariant1,
    midPathOffersBackgroundVariant2,
    midPathOffersBackgroundVariant3,
    midPathOffersBackgroundVariant4,
    midPathOffersBackgroundTestVariant,
    midPathOffersBackgroundTest,
    getCustomQuestionsList,
};
