import { ConfigProvider } from 'providers/index';
import { CommonHelper } from 'helpers';
import { RDS_DEFAULTS } from './rdsDefaults';

const getLabelFromFeature = (key, scope) => {
    const feature = ConfigProvider.Features.getFeature(key, scope) || {};

    return feature.value || '';
};

const getLabel = (key, scope, toDecode, ...defaultParams) => {
    let result = '';

    const featureValue = getLabelFromFeature(key, scope);

    result = featureValue || RDS_DEFAULTS[key](...defaultParams) || '';

    return toDecode ? CommonHelper.decodeEncodedURI(result) : result;
};

export { getLabel };
