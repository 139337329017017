import Utils from 'common/utils';
import { getFeatureMapValue } from './getFeatureValues';
import { FeatureMap } from 'providers';

// ------- Helpers ------- //
export const _filterInvalidSERPListings = listings =>
    listings.filter(item => {
        if (
            !Utils.isNull(item) &&
            !Utils.isNull(item.id) &&
            !Utils.isNull(item.position) &&
            !Utils.isNull(item.page)
        ) {
            return true;
        } else {
            console.error('Config: Invalid SERP listing configured', item);

            return false;
        }
    });

export const getRegNetSpendLeads = () =>
    Utils.jsonSafeParse(getFeatureMapValue(FeatureMap.RDS_REG_LEADS));

export const getRegTCPAText = () => getFeatureMapValue(FeatureMap.REG_TCPA_TEXT);

export const getShowMeDealOfferWallId = () =>
    Utils.jsonSafeParse(getFeatureMapValue(FeatureMap.RDS_SHOW_ME_PAGE_OFFER_WALL_ID));

export const getStandAloneOfferWallId = () =>
    Utils.jsonSafeParse(getFeatureMapValue(FeatureMap.RDS_STAND_ALONE_OFFER_WALL_ID));

export const getOfferPageOfferWallId = () =>
    Utils.jsonSafeParse(getFeatureMapValue(FeatureMap.RDS_BACK_BUTTON_OFFER_WALL_ID));

export const getRDSSurveyDependentQuestions = () =>
    Utils.jsonSafeParse(getFeatureMapValue(FeatureMap.RDSSurveyDependentQuestions));

export const getRDSMidPathBackgroundImages = () => {
    return Utils.jsonSafeParse(getFeatureMapValue(FeatureMap.RDSMidPathBackgroundImage));
};

export const getCustomConfigQuestions = () => {
    return Utils.jsonSafeParse(getFeatureMapValue(FeatureMap.RDS_CUSTOM_QUESTION_CONFIG));
};

export const getPushOptInPlacementValue = () =>
    Utils.jsonSafeParse(getFeatureMapValue(FeatureMap.PushOptInPlacement));
