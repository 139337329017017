import WebStoreManager from './webStoreManager';
import EventManager from '../eventManager/eventManager';
import * as Constants from 'common/constants';

let localStore = null;

// Just using localStorage is an exception in some environments
try {
    localStore = localStorage;
} catch (e) {
    localStore = null;
    EventManager.sendEvent(
        Constants.EVENT.ERROR,
        null,
        Constants.EVENT_TYPE.LOCAL_STORAGE_NOT_ACCESSIBLE,
    );
    console.log('ERR: Error setting the local-store - ', e);
}

const store = new WebStoreManager(localStore);
export default store;
